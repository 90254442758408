import { Card, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PreviewChartCard from "../../components/PreviewChartCard";
import PreviewTable from "../../components/table"
import axiosRequest from "../../utils/axiosRequest";
import {
  HorizontalBarConfigs,
  lineConfigs,
  VerticalBarConfigs,
  labelOptions,
  horizontalLabelOptions
} from "../../utils/lineSettings";
import { pieChartConfig } from "../../utils/pieChartConfig";
import Loader from "../SmallLoader";
import { ControlPointSharp } from "@material-ui/icons";
/*
  item = tab data,
  api = 
  type = 
*/
const CardChartContainer = ({ item, searchQuery, theme, Api, type ,index}) => {
  const [chartWidth, setChartWidth] = useState(6);

  const [chartLoader, setChartLoader] = useState(true);

  const [chartConfig, setChartConfig] = useState({});

  const [chartData, setChartData] = useState({});

  const [chartType, setChartType] = useState({});
 
//   useEffect(() => {
//     if (type?.graph === "bar") {
//       setChartType("bar");
//     } else if (type?.graph === "pie") {
//       setChartType("pie");
//     } else if (type?.graph === "horizontal_stacked_bar") {
//       setChartType("horizontal_stacked_bar");
//     } else if ( type?.graph === "tabular") {
//       setChartType("tabular");

//     }
//     else {
//       setChartType("line");
//     }

//   }, [type]);

  useEffect(() => {
    if (item && item.Slug) {
      setChartLoader(true);

      /**
       * Fetch new chart data whenever there is change in searchQuery
       */

      if (Api === "chartconfig") {
        let search_params = {
          yearfrom: '',
          yearto: '',
          province:'',
          district: '',
          frequency: '',
          seriesType: '',
          level:'',
          year:'',
          month:''
        };
        if(searchQuery.frequency === '1')
        {
          if (searchQuery.filteryearfrom) {
            search_params.yearto = searchQuery.filteryearfrom;
            searchQuery.filteryearto = searchQuery.filteryearfrom;
          }
          else if (searchQuery.filteryearengfrom) {
            search_params.yearto = searchQuery.filteryearengfrom;
            searchQuery.filteryearengto = searchQuery.filteryearengfrom;
          }
          //filter to and from date are same for tab 5
          if( type.subtab_name === "tab5" ){
            if (searchQuery.filtermonthfrom) {
              // search_params.yearto = searchQuery.filtermonthfrom;
              searchQuery.filtermonthto = searchQuery.filtermonthfrom;

            }
            if (searchQuery.filtermonthengfrom) {
              // search_params.yearto = searchQuery.filtermonthengfrom;
              searchQuery.filtermonthengto = searchQuery.filtermonthengfrom;
            }
          }
        }else {
          if( type.subtab_name === "tab5" ){
            if (searchQuery.filteryearfrom) {
              searchQuery.filteryearto = searchQuery.filteryearfrom;
            }
          }
          
        }
        if (searchQuery.level) {
          search_params.level = searchQuery.level;
        }
        if (searchQuery.year) {
          search_params.year = searchQuery.year;
        }
        if (searchQuery.month) {
          if(searchQuery.month > 9 )
          {
            search_params.month = searchQuery.month;
          }
          else{
            search_params.month = '0'+searchQuery.month;
          }
        }
        if (searchQuery.filteryearfrom) {
          search_params.yearfrom = searchQuery.filteryearfrom;
        }
        if (searchQuery.filteryearengfrom) {
          search_params.yearfrom = searchQuery.filteryearengfrom;
        }
        if (searchQuery.filteryearto) {
          search_params.yearto = searchQuery.filteryearto
        }
        if (searchQuery.filteryearengto) {
          search_params.yearto = searchQuery.filteryearengto
        }
        if (searchQuery.filtermonthfrom) {
          search_params.yearfrom = searchQuery.filtermonthfrom
        }
        if (searchQuery.filtermonthto) {
          search_params.yearto = searchQuery.filtermonthto
        }
        if (searchQuery.filtermonthengfrom) {
          search_params.yearfrom = searchQuery.filtermonthengfrom
        }
        if (searchQuery.filtermonthengto) {
          search_params.yearto = searchQuery.filtermonthengto
        }
        if (searchQuery.districts) {
          search_params.district = searchQuery.districts
        }
        if (searchQuery.frequency) {
          search_params.frequency = searchQuery.frequency;
        }
        if (searchQuery.provinces) {
          search_params.province = searchQuery.provinces;
        }
        if (searchQuery.seriesType) {
          search_params.seriesType = searchQuery.seriesType;
        }

        axiosRequest.get(`${process.env.REACT_APP_MAIN_URL}${Api}/${item.Slug}/`, { params: search_params })
          .then((res) => {
            if (res.data.data ) {
              let test = [];
              for (let x in res.data.data) {
                let obj = res.data.data[x];
                for (let y of obj) {
                  for (let z of y) {
                    z.stack = x;
                    test.push(z);
                  }
                }
              }

              const stacktest = test.map((y) => {
               
                let obj = { ...y };

                if (y?.value && y?.value instanceof Array) {
                 
                  const sortedData = y.value.sort((a, b) =>
                    a.name?.id > b.name?.id ? -1 : 1
                  );
                  if (sortedData) {
                    obj.values = sortedData; // set new object values inside object
                    obj.tooltipName = y.name ? y.name : y.mappedName; //set tooltip and table header name
                   // obj.stack = y?.stack;
                    obj.type = y.charttype ? y.charttype : "" ;
                    return obj;
                  }
                }
              });
             

              let newItem = { ...item };
              console.log("newItem",newItem)
              newItem.chartTitle = res.data?.title;
              if( (res.data.islive != "1" || Object.keys(chartConfig).length === 0)){

                newItem.obj.data = stacktest;
                
              }
              setChartConfig(newItem);
              setChartLoader(false);
            }
          })

          .catch((err) => {
            console.log("error", err);
          });
      } else {

        let search_param = {
          yearfrom: '',
          yearto: '',
        };

        if (searchQuery.yearsfrom) {
          search_param.yearfrom = searchQuery.yearsfrom;
        }
        
        if (searchQuery.yearsto) {
          search_param.yearto = searchQuery.yearsto
        }

        axiosRequest.get(`${process.env.REACT_APP_MAIN_URL}api/${Api}/${item.Slug}`, { params: search_param })
          .then((res) => {
           
            if (res.data.data.nogroup.length > 0) {
              if (res.data && res.data.data) {
                const newData = res.data.data.nogroup.map((y) => {
                  let obj = { ...y };
                  if (y?.value && y?.value instanceof Array) {
                   
                    const sortedData = y.value.sort((a, b) =>
                      a.name?.id > b.name?.id ? -1 : 1
                    );

                    if (sortedData) {
                      obj.values = sortedData; // set new object values inside object
                      obj.tooltipName =  y.name ? y.name : y.mappedName; //set tooltip and table header name
                      return obj;
                    }
                  }
                });
                let newItem = { ...res.data };
                newItem.data.nogroup = newData;
                newItem.chartTitle = res.data?.title;
                setChartConfig(newItem);
                setChartLoader(false);
              }
            }
          })

          .catch((err) => {
            console.log("error", err);
          });
      }
    }
  }, [item, searchQuery]);

    /***
   * GET VERTICAL OR HORIZONTAL CHART BASED ON LEGHT OF ARRAY
   */

  const handleBarType = (dataLength) => {
  // console.log(chartConfig)

    /**
     * To dynamically set the width of the chart container.
     * if less than 10 then show 2 columns per row else set 1 column per row.
     */
    if (dataLength > 20) {
      setChartWidth(20);
    } else {
      setChartWidth(10);
    }

  
    /**
     * Set bat configs based on width of chart container
     */
    let newChartData  = { ...VerticalBarConfigs };
    // let newChartData = { ...groupedBarChart }
    /**
     * Create array of legends from data received
     */
    // console.log( 'chartConfig', newChartData)
    if (chartConfig.obj) {
      //   newChartData.legend.data = chartConfig.obj.data?.map(
      //     (x) => x.tooltipName
      //   );
      let timeSeries = [];

      chartConfig.obj.data?.map((x) => {
        if(x.values instanceof Array) {
          x.values.forEach(element => {
            
            let i = timeSeries.findIndex(el => el == element.name);
            if(i == -1){
              timeSeries.push(element.name);
            }
          });
        }
      })
      if(timeSeries.length > 0){
        timeSeries = timeSeries.sort()
      }
      let seriesData = chartConfig.obj.data?.map((x) => {
       
        let mainObj = {
          type: x.type ?  x.type === "none" ? "bar" : x.type : "bar", //chart type
         // stack: x.stack === "nogroup" || x.stack === "" ? "" : x.stack ,
         // type: "bar", //chart type
          // stack: x.stack ? x.stack : "",
          name: x.tooltipName, // value that is shown on tooltip when hovered
          label : labelOptions,
          emphasis: {
            focus: 'series'
          },
          // barGap : '0%',

        };

       
        if (x.values) {
          /**
           * Set axis of chart based on length of data or chart container
           */
           let arr = [];
          if(x.values instanceof Array){
           
            timeSeries.forEach(element => {
              let i = x.values.findIndex(el => el.name == element);
              if(i != -1){
                arr.push(x.values[i].value);
              } else {
                arr.push(0)
              }
            });
          }
          newChartData[dataLength > 40 ? "yAxis" : "xAxis"] = {
            type: "category",
            data:
              x.values instanceof Array
                ? x.values?.map((y) => {
                  return y.name;
                })
                : [],
            axisLabel: {
              show: dataLength < 20,
              interval: 0, //show all names / donot skip any labels
              rotate: 60, // rotate albels by 30 degree
              formatter: function (name) {
                const twoLines = name.replace(" ", "\n");
                const shortName = name.substring(0, 15); //show only 10 strinh from name
                return dataLength > 10 ? shortName : twoLines;
              },
              textStyle: {
                fontSize: 11,
              },
            },
            name: item.x_axis_name,
            nameLocation: "middle",
            nameGap: dataLength > 40 ? 60 : 40,
          };
          newChartData[dataLength > 40 ? "xAxis" : "yAxis"] = [
            {
              type: "value",
              name: item.y_axis_name,
              nameLocation: "middle",
              nameGap: 60,
              axisLabel: {
                formatter: function (value) {
                    if (value >= 1000000000) {
                        return (value / 1000000000).toFixed(0) + 'B';
                    } else if (value >= 1000000) {
                        return (value / 1000000).toFixed(0) + 'M';
                    } else if (value >= 1000) {
                        return (value / 1000).toFixed(0) + 'K';
                    } else {
                        return value;
                    }
                },
                textStyle: {
                  fontSize: 11,
                },
                // rotate : 20
            }
            },
          ];
          mainObj.data =
            x.values && x.values instanceof Array
              ? arr
              : [];

        }
        return mainObj;
      });
      // console.log( 'series data', seriesData)
      newChartData.series = seriesData; // add data transformed above to series key on main chart object. Main chart object is based on echarts

      return newChartData;
    } else {
      //   newChartData.legend.data = chartConfig.data.nogroup?.map(
      //     (x) => x.tooltipName
      //   );

      let timeSeries = [];

      chartConfig.data.nogroup?.map((x) => {
        if(x.values instanceof Array) {
          x.values.forEach(element => {
            
            let i = timeSeries.findIndex(el => el == element.name);
            if(i == -1){
              timeSeries.push(element.name);
            }
          });
        }
      })
      if(timeSeries.length > 0){
        timeSeries = timeSeries.sort()
      }
     

      let seriesData = chartConfig.data.nogroup?.map((x) => {
       
        let mainObj = {
          type: x.type ?  x.type === "none" ? "bar" : x.type : "bar", //chart type
        //  stack: x.stack ? x.stack : "",
          // stack: x.stack ? x.stack : "",
          name: x.tooltipName, // value that is shown on tooltip when hovered
          label : labelOptions,
          emphasis: {
            focus: 'series'
          },
          emphasis: {
            focus: 'series'
          },
          barGap : '10%'
        };

        if (x.values) {
          /**
           * Set axis of chart based on length of data or chart container
           */
          
           let arr = [];
           if(x.values instanceof Array){
            
             timeSeries.forEach(element => {
               let i = x.values.findIndex(el => el.name == element);
               if(i != -1){
                 arr.push(x.values[i].value);
               } else {
                 arr.push(0)
               }
             });
           }
          
          
          newChartData[dataLength > 40 ? "yAxis" : "xAxis"] = {
            type: "category",
            data:
              x.values instanceof Array
                ? x.values?.map((y) => {
                  return y.name;
                })
                : [],
            axisLabel: {
              show: dataLength < 20,
              interval: 0, //show all names / donot skip any labels
              rotate: 30, // rotate albels by 30 degree
              formatter: function (name) {
                const twoLines = name.replace(" ", "\n");
                const shortName = name.substring(0, 15); //show only 10 strinh from name
                return dataLength > 10 ? shortName : twoLines;
              },
              textStyle: {
                fontSize: 10,
              },
            },
            name: item.x_axis_name,
            nameLocation: "middle",
            nameGap: dataLength > 40 ? 100 : 50,
          };
          newChartData[dataLength > 40 ? "xAxis" : "yAxis"] = [
            {
              type: "value",
              name: item.y_axis_name,
              nameLocation: "middle",
              nameGap: 60,
            },
          ];
          mainObj.data =
            x.values && x.values instanceof Array
              ? arr
              : [];
        }
       
        return mainObj;
      });
      // console.log( seriesData , 'series data');
      newChartData.series = seriesData; // add data transformed above to series key on main chart object. Main chart object is based on echarts

      return newChartData;
    }
  };

  const handleHBarType = (dataLength) => {
    // console.log(chartConfig)

    /**
     * To dynamically set the width of the chart container.
     * if less than 10 then show 2 columns per row else set 1 column per row.
     */
    if (dataLength > 10) {
      setChartWidth(20);
    } else {
      setChartWidth(10);
    }

    /**
     * Set bat configs based on width of chart container
     */
    let newChartData = { ...HorizontalBarConfigs };

    /**
     * Create array of legends from data received
     */
   
    if (chartConfig.obj) {
      //   newChartData.legend.data = chartConfig.obj.data?.map(
      //     (x) => x.tooltipName
      //   );
      let timeSeries = [];

      chartConfig.obj.data?.map((x) => {
        if(x.values instanceof Array) {
          x.values.forEach(element => {
            
            let i = timeSeries.findIndex(el => el == element.name);
            if(i == -1){
              timeSeries.push(element.name);
            }
          });
        }
      })
      if(timeSeries.length > 0){
        timeSeries = timeSeries.sort()
      }
     
      

      let seriesData = chartConfig.obj.data?.map((x) => {
        let mainObj = {
          type: "bar", //chart type
         // stack: x.stack ? x.stack : "",
          name: x.tooltipName, // value that is shown on tooltip when hovered
          label: horizontalLabelOptions,
          barGap : '0%',
          emphasis: {
            focus: 'series'
          },
        };
  


       
        if (x.values) {
          /**
           * Set axis of chart based on length of data or chart container
           */
           let arr = [];
          if(x.values instanceof Array){
           
            timeSeries.forEach(element => {
              let i = x.values.findIndex(el => el.name == element);
              if(i != -1){
                arr.push(x.values[i].value);
              } else {
                arr.push(0)
              }
            });
          }

          
          newChartData[dataLength > 0 ? "yAxis" : "xAxis"] = {
            type: "category",
            data:
              x.values instanceof Array
                ? x.values?.map((y) => {
                  return y.name;
                })
                : [],
            axisLabel: {
              show: dataLength < 20,
              interval: 0, //show all names / donot skip any labels
              rotate: 30, // rotate albels by 30 degree
              formatter: function (name) {
                const twoLines = name.replace(" ", "\n");
                const shortName = name.substring(0, 15); //show only 10 strinh from name
                return dataLength > 10 ? shortName : twoLines;
              },
              textStyle: {
                fontSize: 10,
              },
            },
            name: item.x_axis_name,
            nameLocation: "middle",
            nameGap: dataLength > 0 ? 60 : 30,
          };
          newChartData[dataLength > 0 ? "xAxis" : "yAxis"] = [
            {
              type: "value",
              name: item.y_axis_name,
              nameLocation: "middle",
              nameGap: 60,
            },
          ];
          mainObj.data =
            x.values && x.values instanceof Array
              ? arr
              : [];
        }
        return mainObj;
      });
     
      newChartData.series = seriesData; // add data transformed above to series key on main chart object. Main chart object is based on echarts

      return newChartData;
    } else {
      //   newChartData.legend.data = chartConfig.data.nogroup?.map(
      //     (x) => x.tooltipName
      //   );

      let timeSeries = [];

      chartConfig.data.nogroup?.map((x) => {
        if(x.values instanceof Array) {
          x.values.forEach(element => {
            
            let i = timeSeries.findIndex(el => el == element.name);
            if(i == -1){
              timeSeries.push(element.name);
            }
          });
        }
      })
      if(timeSeries.length > 0){
        timeSeries = timeSeries.sort()
      }
     

      let seriesData = chartConfig.data.nogroup?.map((x) => {
        let mainObj = {
          type: "bar", //chart type
         // stack: x.stack ? x.stack : "",
          name: x.tooltipName, // value that is shown on tooltip when hovered
          label: horizontalLabelOptions,
          barGap : '10%',
          emphasis: {
            focus: 'series'
          },

        };

        if (x.values) {
          /**
           * Set axis of chart based on length of data or chart container
           */
          
           let arr = [];
           if(x.values instanceof Array){
            
             timeSeries.forEach(element => {
               let i = x.values.findIndex(el => el.name == element);
               if(i != -1){
                 arr.push(x.values[i].value);
               } else {
                 arr.push(0)
               }
             });
           }
          
          
          newChartData[dataLength > 40 ? "yAxis" : "xAxis"] = {
            type: "category",
            data:
              x.values instanceof Array
                ? x.values?.map((y) => {
                  return y.name;
                })
                : [],
            axisLabel: {
              show: dataLength < 20,
              interval: 0, //show all names / donot skip any labels
              rotate: 30, // rotate albels by 30 degree
              formatter: function (name) {
                const twoLines = name.replace(" ", "\n");
                const shortName = name.substring(0, 15); //show only 10 strinh from name
                return dataLength > 10 ? shortName : twoLines;
              },
              textStyle: {
                fontSize: 10,
              },
            },
            name: item.y_axis_name,
            nameLocation: "middle",
            nameGap: dataLength > 40 ? 100 : 50,
          };
          newChartData[dataLength > 40 ? "xAxis" : "yAxis"] = [
            {
              type: "value",
              name: item.x_axis_name,
              nameLocation: "middle",
              nameGap: 30,
            },
          ];
          mainObj.data =
            x.values && x.values instanceof Array
              ? arr
              : [];
        }
       
        return mainObj;
      });
      newChartData.series = seriesData; // add data transformed above to series key on main chart object. Main chart object is based on echarts

      return newChartData;
    }
    //rotate label 
  };

  const handleTabularType = ( dataLength ) => { 
        /**
     * To dynamically set the width of the chart container.
     * if less than 10 then show 2 columns per row else set 1 column per row.
     */
         if (dataLength > 10) {
          setChartWidth(20);
        } else {
          setChartWidth(10);
        }
    
        /**
         * Set bat configs based on width of chart container
         */
        let newChartData = { ...HorizontalBarConfigs } ;
        // let newChartData =
        //   dataLength > 0 ?{ ...HorizontalBarConfigs }: { ...HorizontalBarConfigs };
    
        /**
         * Create array of legends from data received
         */
        // console.log( chartConfig , 'chart config objj')
        if (chartConfig.obj) {
          //   newChartData.legend.data = chartConfig.obj.data?.map(
          //     (x) => x.tooltipName
          //   );
          let timeSeries = [];
    
          chartConfig.obj.data?.map((x) => {
            if(x.values instanceof Array) {
              x.values.forEach(element => {
                
                let i = timeSeries.findIndex(el => el == element.name);
                if(i == -1){
                  timeSeries.push(element.name);
                }
              });
            }
          })
          if(timeSeries.length > 0){
            timeSeries = timeSeries.sort()
          }
         
          
    
          let seriesData = chartConfig.obj.data?.map((x) => {
            let mainObj = {
              type: "bar", //chart type
              stack: x.stack ? x.stack : "",
              name: x.tooltipName, // value that is shown on tooltip when hovered
              barGap : '0%',
              emphasis: {
                focus: 'series'
              },
              label : labelOptions
            };
    
           
            if (x.values) {
              /**
               * Set axis of chart based on length of data or chart container
               */
               let arr = [];
              if(x.values instanceof Array){
               
                timeSeries.forEach(element => {
                  let i = x.values.findIndex(el => el.name == element);
                  if(i != -1){
                    arr.push(x.values[i].value);
                  } else {
                    arr.push(0)
                  }
                });
              }
    
              
              // newChartData[dataLength > 0 ? "yAxis" : "xAxis"] = {
              newChartData["yAxis"] = {
                type: "category",
                data:
                  x.values instanceof Array
                    ? x.values?.map((y) => {
                      return y.name;
                    })
                    : [],
                axisLabel: {
                  show: dataLength < 20,
                  interval: 0, //show all names / donot skip any labels
                  rotate: 30, // rotate albels by 30 degree
                  formatter: function (name) {
                    const twoLines = name.replace(" ", "\n");
                    const shortName = name.substring(0, 15); //show only 10 strinh from name
                    return dataLength > 10 ? shortName : twoLines;
                  },
                  textStyle: {
                    fontSize: 10,
                  },
                },
                name: item.y_axis_name,
                nameLocation: "middle",
                nameGap: dataLength > 0 ? 60 : 30,
              };
              newChartData["xAxis"] = [
                {
                  type: "value",
                  name: item.x_axis_name,
                  nameLocation: "middle",
                  nameGap: 60,
                },
              ];
              mainObj.data =
                x.values && x.values instanceof Array
                  ? arr
                  : [];
            }
            return mainObj;
          });
          newChartData.series = seriesData; // add data transformed above to series key on main chart object. Main chart object is based on echarts
    
          return newChartData;
        } else {
          //   newChartData.legend.data = chartConfig.data.nogroup?.map(
          //     (x) => x.tooltipName
          //   );
    
          let timeSeries = [];
    
          chartConfig.data.nogroup?.map((x) => {
            if(x.values instanceof Array) {
              x.values.forEach(element => {
                
                let i = timeSeries.findIndex(el => el == element.name);
                if(i == -1){
                  timeSeries.push(element.name);
                }
              });
            }
          })
          if(timeSeries.length > 0){
            timeSeries = timeSeries.sort()
          }
         
    
          let seriesData = chartConfig.data.nogroup?.map((x) => {
            let mainObj = {
              type: "bar", //chart type
              stack: x.stack ? x.stack : "",
              name: x.tooltipName, // value that is shown on tooltip when hovered
              barGap : '10%',
              emphasis: {
                focus: 'series'
              },
              label : labelOptions
    
            };
    
            if (x.values) {
              /**
               * Set axis of chart based on length of data or chart container
               */
              
               let arr = [];
               if(x.values instanceof Array){
                
                 timeSeries.forEach(element => {
                   let i = x.values.findIndex(el => el.name == element);
                   if(i != -1){
                     arr.push(x.values[i].value);
                   } else {
                     arr.push(0)
                   }
                 });
               }
              
              
              newChartData["yAxis"] = {
                type: "category",
                data:
                  x.values instanceof Array
                    ? x.values?.map((y) => {
                      return y.name;
                    })
                    : [],
                axisLabel: {
                  show: dataLength < 20,
                  interval: 0, //show all names / donot skip any labels
                  rotate: 30, // rotate albels by 30 degree
                  formatter: function (name) {
                    const twoLines = name.replace(" ", "\n");
                    const shortName = name.substring(0, 15); //show only 10 strinh from name
                    return dataLength > 10 ? shortName : twoLines;
                  },
                  textStyle: {
                    fontSize: 10,
                  },
                },
                name: item.y_axis_name,
                nameLocation: "middle",
                nameGap: dataLength > 40 ? 100 : 50,
              };
              newChartData["xAxis"] = [
                {
                  type: "value",
                  name: item.x_axis_name,
                  nameLocation: "middle",
                  nameGap: 30,
                },
              ];
              mainObj.data =
                x.values && x.values instanceof Array
                  ? arr
                  : [];
            }
           
            return mainObj;
          });
          
          newChartData.series = seriesData; // add data transformed above to series key on main chart object. Main chart object is based on echarts
    
          return newChartData;
        }

  };

  /***
   * SET DATA BASED ON TYPE
   */

  const setDataBasedOnType = (type) => {
     if (type === "horizontal_stacked_bar") {
      /***
       * Function return chart config based on length of data | chart config is to control vertical and horizontal bar chart.
       */
      if (chartConfig.obj) {
        const newDataSet = handleHBarType(
          chartConfig.obj.data[0]?.values?.length
        );
        setChartData(newDataSet);
      } else {
        const newDataSet = handleHBarType(
          chartConfig.data.nogroup[0]?.values?.length
        );
        setChartData(newDataSet);
      }
    } 
  };

  /***
   * CREATE CHART DATA BASED ON CHART TYPE
   */

  useEffect(() => {
    if (Object.values(chartConfig).length > 0) {
    //    if (chartConfig.charttype === "pieChart" || chartType === "pie") {
    //    setDataBasedOnType("pie");
       
    //   } else if (chartType === "bar" || chartConfig.charttype === "barChart") {
    //     setDataBasedOnType("bar");
    //   } else if (chartType === "line"  || chartConfig.charttype === "lineChart") {
    //     setDataBasedOnType("line");
    //   }
    //   else if (chartType === "horizontal_stacked_bar" || chartConfig.charttype === "horizontal_stacked_bar") {
    //     setDataBasedOnType("horizontal_stacked_bar");
    //   }
    //   else if (chartType === "tabular" || chartConfig.charttype === "tabularChart") {
    //     setDataBasedOnType("tabular");
    //   }
    setDataBasedOnType("card");
    }
  }, [chartConfig]);

  return (
    <div  className={ (chartConfig.charttype === "tabular" ? "tabular-view" : 'preview-view') }>
      { 
        <>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <Card
              className="graph__card"
              id={item.Slug}
              style={{ overflow: "unset" }}
            >
              <Typography variant="subtitle1" align="center">
                {chartConfig.chartTitle}
              </Typography>
              <div>
                {chartConfig.obj && chartConfig.obj.data[0] && chartConfig.obj.data[0].values && chartConfig.obj.data[0].values.length > 0  ? 
                <div className="flex-display"><div>
                  {/* <span>Year : {chartConfig.obj.data[0].values[chartConfig.obj.data[0].values.length - 1].name} </span> */}
                <span className="year-section">{chartConfig.obj.data[0].values[chartConfig.obj.data[0].values.length - 1].value}</span></div>
               </div> : <></>}
              </div>
            </Card>
          </Grid>
        </>
      }
    </div>
  );
};

export default CardChartContainer;
