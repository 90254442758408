import React from 'react'
import styled from 'styled-components';

const FooterWrapper = styled.section`
    max-height: 8vh;
    width: 100%;
`;
const FooterText = styled.p`
    text-align:center;
    font-size: 0.8rem;
    margin: auto 0;
    padding: 10px;

`;

const Footer = () => {
    return (
        <FooterWrapper>
            <FooterText>
                Copyright © {new Date().getFullYear()} <a target="_blank" href="https://nepalntp.gov.np/">NTCC</a>  | Disclaimer: Data to be used for monitoring purposes only.
            </FooterText>
        </FooterWrapper>
    )
}

export default Footer
