import Table from './Table'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Loader from '../../../../components/Loader'
import axiosRequest from '../../../../utils/axiosRequest'

const TablesData = [
    {
        id: Math.random(),
        title: "",
        color: "primary",
        header: [
            { id: Math.random(), hName: "TOTAL GX SITE" },
            { id: Math.random(), hName: "Enrolled In GMIX " },
            { id: Math.random(), hName: "Not Enrolled " },
        ],
        rows: [
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 71 },
                    { id: Math.random, value: 36 },
                    { id: Math.random, value: 35 },
                ]
            }
        ]

    },
    {
        id: Math.random(),
        title: "GeneXpert Machine Status",
        color: "secondary",
        header: [
            { id: Math.random(), hName: "Machine Running Today" },
            { id: Math.random(), hName: "Count " },
        ],
        rows: [
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'SETI ZONAL HOSPITAL_KAILALI_KAILALI' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'TB-Nepal_Banke' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'LUMBINI ZONAL HOSPITAL_ RUPANDEHI_RUPANDEHI' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'Grahun Primary Hospital' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'aaNEPAL ANTI TUBERCLOSIS ASSOCIATION (NATA)_MORANG' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'MID WESTERN REGIONAL HOSPITAL_ SURKHET_SURKHET' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'DAMAULI DISTRICT HOSPITAL_ TANAHU_TANAHU' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'LAMAHI HOSPITAL DANG' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'NOBEL MEDICAL COLLEGE_MORANG' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'Western Regional Health TB Center_ Pokhara' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'GENETOP_KATHMANDU' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'NATIONAL TUBERCULOSIS CENTRE_BHAKTAPUR' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'DHULABARI PHC_JHAPA' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'MID POINT COMMUNITY HOSPITAL_NAWALPARASI' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'UNITED MISSION HOSPITAL_ PALPA_PALPA' },
                    { id: Math.random, value: null },
                ]
            },
        ]

    },
    {
        id: Math.random(),
        title: "Delay for 1-2 days",
        color: "fourth",
        header: [
            { id: Math.random(), hName: "Machine Running Today" },
            { id: Math.random(), hName: "Count " },
        ],
        rows: [
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'TIKAPUR HOSPITAL_ KAILALI' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'PIPARA SIMARA PHC_BARA' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'NEPAL POLICE HOSPITAL_KATHMANDU' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'BAYALPATA HOSPITAL (NYAYA HEALTH)_ACHHAM' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'MAHAKALI ZONAL HOSPITAL_KANCHANPUR' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'Laxminarayan Referral Hospital Kailali' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'LUMBINI MEDICAL COLLEGE_PALPA' },
                    { id: Math.random, value: null },
                ]
            },
        ]
    },
    {
        id: Math.random(),
        title: "Delay for 3-4 days",
        color: "fifth",
        header: [
            { id: Math.random(), hName: "Sites" },
            { id: Math.random(), hName: "Count " },
        ],
        rows: [
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'BPKIHS_ DHARAN_SUNSARI' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'BAKULAHAR HOSPITAL _CHITAWAN' },
                    { id: Math.random, value: null },
                ]
            },

        ]
    },
    {
        id: Math.random(),
        title: "Delay for 5-6 days",
        color: "sixth",
        header: [
            { id: Math.random(), hName: "Sites" },
            { id: Math.random(), hName: "Count " },
        ],
        rows: [
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'TAMGHAS HOSPITAL_ GULMI_GULMI' },
                    { id: Math.random, value: null },
                ]
            },

        ]
    },
    {
        id: Math.random(),
        title: "More Than 7 days",
        color: "seventh",
        header: [
            { id: Math.random(), hName: "Sites" },
            { id: Math.random(), hName: "Count " },
        ],
        rows: [
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'NARAYANI HOSPITAL_PARSA' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'DHAULAGIRI ZONAL HOSPITAL_BAGLUNG' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'MANGALBARE Hospital MORANG_MORANG' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'DHULIKHEL HOSPITAL _KAVRE' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'RAPTI SUB REGIONAL HOSPITAL_DANG' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'BHIM HOSPITAL_ RUPANDEHI' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'MANIPAL TEACHING HOSPITAL_KASKI' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'OKHALDHUNGA COMMUNITY HOSPITAL_OKHALDHUNGA' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'NATIONAL TUBERCULOSIS CENTRE_BHAKTAPUR' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'NATIONAL TUBERCULOSIS CENTRE_BHAKTAPUR' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'NATIONAL TUBERCULOSIS CENTRE_BHAKTAPUR' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'DISTRICT HOSPITAL_ ILAM_ILAM' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'NATIONAL TUBERCULOSIS CENTRE_BHAKTAPUR' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'DISTRICT HOSPITAL_ UDAYAPUR_UDAYAPUR' },
                    { id: Math.random, value: null },
                ]
            },
            {
                id: Math.random(),
                columns: [
                    { id: Math.random, value: 'MID POINT COMMUNITY HOSPITAL_NAWALPARASI' },
                    { id: Math.random, value: null },
                ]
            },

        ]
    },
]

const colorArray = ["primary", "secondary", "fourth", "fifth", "sixth", "seventh"]


const Gxmis = () => {

    const [gxmisDelayData, setGxmisDelayData] = useState([])
    const [gxmisOnlineData, setGxmisOnlineData] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        axios.get("https://ntpmis.gov.np/ntcc/api/delay_machine")
            .then(res => {
                if (res.data && res.data.data) {
                    setGxmisDelayData(res.data.data)
                }
                setLoading(false)
            })
    }, [])
    useEffect(() => {
        axios.get("https://ntpmis.gov.np/ntcc/api/machine_online")
            .then(res => {
                if (res.data && res.data.data) {
                    setGxmisOnlineData(res.data.data)
                }
                setLoading(false)
            })
    }, [])

    const tableHeader = [
        { id: Math.random(), hName: "Sites" },
        // { id: Math.random(), hName: "Count " }
    ]

    return (
        <>
            {
                gxmisOnlineData.length < 1 || gxmisDelayData.length < 1 ?
                    <Loader />
                    :
                    <>
                        {
                            gxmisOnlineData.map((x, i) => {
                                if (x?.value instanceof Array) {
                                    return (
                                        <Table
                                            key={x.key}
                                            header={tableHeader}
                                            rows={x.value}
                                            color={colorArray[i] || 'primary'}
                                            title={x.key}
                                        />

                                    )
                                }
                                return (
                                    <Table
                                        key={x.key}
                                        header={tableHeader}
                                        rows={[x.value]}
                                        color={colorArray[i] || 'primary'}
                                        title={x.key}
                                        noCount
                                    />
                                )
                            })
                        }
                        {
                            gxmisDelayData.map((x, i) => {
                                if (x?.value instanceof Array) {
                                    return (
                                        <Table
                                            key={x.key}
                                            header={tableHeader}
                                            rows={x.value}
                                            color={colorArray[i] || 'primary'}
                                            title={x.key}
                                        />

                                    )
                                }
                                return (
                                    <></>
                                )
                            })
                        }
                    </>

            }

        </>
    )
}

export default Gxmis
