import axiosRequest from "../../utils/axiosRequest"
import axiosRequests from "utils/axiosRequests"
import { FETCH_CHART_LIST_LOADING, FETCH_CHART_LIST_SUCCESS } from "./chartListActionTypes"

export const fetchChartList = (fetchSlug) => dispatch => {

    dispatch({
        type: FETCH_CHART_LIST_LOADING
    })
    axiosRequest.get( fetchSlug +"/")
        .then(res => {
            dispatch({
                type: FETCH_CHART_LIST_SUCCESS,
                payload: res.data
            })
        })
        .catch(err => {
            console.log(err)
        })
}

export const fetchArchiveList = (fetchSlugs) => dispatch =>{
    dispatch({
        type: FETCH_CHART_LIST_LOADING
    })
    axiosRequests.get(fetchSlugs)
        .then(res => {
            
            dispatch({
                type: FETCH_CHART_LIST_SUCCESS,
                payload: res.data
            })
        })
        .catch(err => {
            console.log(err)
        })
}