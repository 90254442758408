import { Button, Grid, makeStyles, Tooltip } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React, { useEffect, useState } from "react";
import { GiConsoleController } from "react-icons/gi";
import { useSelector, useDispatch } from "react-redux";
import { fetYearsData } from "store/search/searchAction";
import { monthToString } from "utils/monthToString";
import axiosRequest from "utils/axiosRequest";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FaAlignJustify} from "react-icons/fa";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: "10px 0 0 0",
    width: "100%",
    maxHeight: 50,
    color: "red",
    "&.Mui-focused": {
      color: "red",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonStyle: {
    padding: "15px auto",
  },
  radioLevel:{
    maxWidth: "100% !important" ,
    display: "flex" ,
    alignItems: "center"
 }   


}));

const Search = ({
  searchQuery,
  handleSearchInputChange,
  highlightType,
  setHighlightType,
  type,
  Api,
  index,
  mindex,
}) => {
  // const useStyles = makeStyles((theme) => ({
  //   root: {
  //     width: '100%',
  //   },
  //   heading: {
  //     fontSize: theme.typography.pxToRem(15),
  //     fontWeight: theme.typography.fontWeightRegular,
  //   },
  // }));
  const initialgeography = "";
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchsArrayData = useSelector((state) => state.searchData.data);
  const [months, setMonths] = useState();
  const [FilterYearfrom, setFilterYearfrom] = useState();
  const [FilterYearto, setFilterYearto] = useState();
  const [FilterMonthfrom, setFilterMonthfrom] = useState();
  const [FilterMonthto, setFilterMonthto] = useState();
  const [districts, setDistricts] = useState();
  const [Provinces, setProvinces] = useState();
  const [filter, setFilters] = useState();
  const [filters, setFilter] = useState();
  const [engYearfrom, setengYearfrom] = useState();
  const [engYearto, setengYearto] = useState();
  const [engMonthfrom, setengMonthfrom] = useState();
  const [engMonthto, setengMonthto] = useState();
  const [searchFormKey, setSearchFormKey] = useState(1);
  const [state, setState] = useState("4");
  const [geography, setGeography] = useState(initialgeography);
  const [geoprovince, setgeoProvinces] = useState();
  const [unit, setUnit] = useState([]);
  const [yearsto, setyearsto] = useState();
  const [yearsfrom, setyearsfrom] = useState();
  const [month, setMonth] = useState();
  const [montheng, setMontheng] = useState();

  useEffect(() => {
    setSearchFormKey(searchFormKey + 1);
    handleSearchInputChange(searchQuery);
    setGeography(initialgeography);
    setState("4");
  }, [index]);
  useEffect(() => {
    setSearchFormKey(searchFormKey + 1);
    handleSearchInputChange(searchQuery);
    setGeography(initialgeography);
    setState("4");
  }, [mindex]);
  const resetSearchForm = () => {
    setSearchFormKey(searchFormKey + 1);
    handleSearchInputChange(searchQuery);
    setGeography(initialgeography);
    setState("4");
    // setFilterYearfrom([])
  };
  const handleYearsTo = () => {
    axiosRequest
      .get(process.env.REACT_APP_YEAR_SEARCH)
      .then((res) => {
        let fil = [];
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].value === searchQuery.yearsfrom) {
            for (let j = i; j < res.data.length; j++) {
              fil.push(res.data[j]);
            }
          }
        }

        setyearsto(fil);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleYearsFrom = () => {
    axiosRequest
      .get(process.env.REACT_APP_YEAR_SEARCH)
      .then((res) => {
        setyearsfrom(res.data);
        // if( frequency == 1){
        //   searchQuery.yearsfrom = res.data.at(-1).period_year
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDistricts = () => {
    axiosRequest
      .get(process.env.REACT_APP_MAIN_URL + "api/getfilterdata/districts")
      .then((res) => {
        let items = [];
        res.data.map((item) => {
          if (geoprovince === item.province_id) {
            items.push(item);
          }
        });
        setDistricts(items);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleProvincials = () => {
    axiosRequest
      .get(process.env.REACT_APP_MAIN_URL + "api/getfilterdata/provinces")
      .then((res) => {
        setProvinces(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFilterYearfrom = () => {
    axiosRequest
      .get(process.env.REACT_APP_MAIN_URL + "api/getfilterdata/year")
      .then((res) => {
        setFilterYearfrom(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFilterYearto = () => {
    axiosRequest
      .get(process.env.REACT_APP_MAIN_URL + "api/getfilterdata/year")
      .then((res) => {
        let fil = [];
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].period_year === searchQuery.filteryearfrom) {
            for (let j = i; j < res.data.length; j++) {
              fil.push(res.data[j]);
            }
          }
        }

        setFilterYearto(fil);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFilterMonthfrom = () => {
    axiosRequest
      .get(process.env.REACT_APP_MAIN_URL + "api/getfilterdata/month")
      .then((res) => {
        let filterdate = [];
        let year = [];
        res.data.map((item) => {
          if (item.year === searchQuery.filteryearfrom) {
            filterdate.push(item);
            console.log("filteryears")
          }
          else (item.year === searchQuery.year)
          {
            year.push(item);
            console.log("year")
          }
        });
        setFilterMonthfrom(filterdate);
        setMonth(year);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleMonth= () => {
    axiosRequest
      .get(process.env.REACT_APP_MAIN_URL + "api/getfilterdata/month")
      .then((res) => {
        let year = [];
        res.data.map((item) => {
         
          if(item.year === searchQuery.year)
          {
            year.push(item);
            
          }
        });
        
        setMonth(year);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleMontheng= () => {
    axiosRequest
      .get(process.env.REACT_APP_MAIN_URL + "api/getfilterdata/montheng")
      .then((res) => {
        let year = [];
        res.data.map((item) => {
         
          if(item.year === searchQuery.year)
          {
            year.push(item);
            
          }
        });
        
        setMontheng(year);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFilterMonthto = () => {
    axiosRequest
      .get(process.env.REACT_APP_MAIN_URL + "api/getfilterdata/month")
      .then((res) => {
        console.log("check", searchQuery.filteryearto);
        let filmonthto = [];
        res.data.map((item) => {
          if (item.year === searchQuery.filteryearto) {
            filmonthto.push(item);
          }
        });

        setFilterMonthto(filmonthto);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFilterYearengfrom = () => {
    axiosRequest
      .get(process.env.REACT_APP_MAIN_URL + "api/getfilterdata/yeareng")
      .then((res) => {
        setengYearfrom(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFilterYearengto = () => {
    axiosRequest
      .get(process.env.REACT_APP_MAIN_URL + "api/getfilterdata/yeareng")
      .then((res) => {
        let fil = [];
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].period_year === searchQuery.filteryearengfrom) {
            for (let j = i; j < res.data.length; j++) {
              fil.push(res.data[j]);
            }
          }
        }

        setengYearto(fil);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFilterMonthengfrom = () => {
    axiosRequest
      .get(process.env.REACT_APP_MAIN_URL + "api/getfilterdata/montheng")
      .then((res) => {
        let filterdate = [];
        res.data.map((item) => {
          if (item.year === searchQuery.filteryearengfrom) {
            filterdate.push(item);
          }
        });
        setengMonthfrom(filterdate);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFilterMonthengto = () => {
    axiosRequest
      .get(process.env.REACT_APP_MAIN_URL + "api/getfilterdata/montheng")
      .then((res) => {
        let filmonthto = [];
        res.data.map((item) => {
          if (item.year === searchQuery.filteryearengto) {
            filmonthto.push(item);
          }
        });

        setengMonthto(filmonthto);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (type) {
      setFilters(type.frequency);
      setFilter(type.unit);
      setUnit(type.unit);
    }
  }, [type]);

  useEffect(() => {
    searchQuery.filtermonthfrom = "";
  }, [searchQuery.filteryearfrom]);

  useEffect(() => {
    searchQuery.filtermonthto = "";
  }, [searchQuery.filteryearto]);

  useEffect(() => {
    searchQuery.filtermonthengfrom = "";
  }, [searchQuery.filteryearengfrom]);

  useEffect(() => {
    searchQuery.filtermonthengto = "";
  }, [searchQuery.filteryearengto]);

  const onValueChange = (event) => {
    setState(event.target.value);
    // if(event.target.value == 1 ){
    //   handleYearsFrom();
    // }
    handleSearchInputChange(event);
  };

  const handleGeographyChange = (event) => {
    setGeography(event.target.value);
    //reset province and district if national is selected
    if (event.target.value == "National") {
      searchQuery.provinces = "";
      searchQuery.districts = "";
      handleSearchInputChange(searchQuery);
    }
  };

  const handlegeoProvinceChange = (event) => {
    setgeoProvinces(event.target.value);
  };

  const handleLevelChange = (event) => {
    handleSearchInputChange(event);
  };

  return (
    <div key={searchFormKey}>
      <div >
        <Accordion className="filter-section">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header "
          >
            <Typography component={'span'} className={classes.heading}> <FaAlignJustify className="filterIcon"/> Filters</Typography>
          </AccordionSummary>
          <AccordionDetails className="top-section">
            <Typography component={'div'}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={12} md={6} lg={3} >
                  <FormControl
                    size="small"
                    color="secondary"
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Select Series
                    </InputLabel>
                    <Select
                      name="seriesType"
                      value={searchQuery.seriesType}
                      onChange={handleSearchInputChange}
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      label="Select Series Type "
                    >


                      <MenuItem value="1">Time Series</MenuItem>
                      {Api && Api != "archived_chart" ?
                        <MenuItem value="2">Geography Series</MenuItem>
                        :
                        <></>
                      }

                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {Api && Api === "archived_chart" ? (
                <>
                  <Grid spacing={2} alignItems="center" >
                    {filter &&
                      Api === "archived_chart" &&
                      filter.map((x, i) => {
                        return x && x === "year" ? (
                          <div key="i">

                            <Grid item xs={12} sm={12} md={6} lg={3} key={i}>

                              <FormControl
                                size="small"
                                color="secondary"
                                variant="outlined"
                                className={classes.formControl}
                              >
                                <InputLabel id="demo-simple-select-outlined-label">
                                  Select Year From
                                </InputLabel>
                                <Select
                                  name="yearsfrom"
                                  value={searchQuery.yearsfrom}
                                  onChange={handleSearchInputChange}
                                  onFocus={handleYearsFrom}
                                  MenuProps={{
                                    disableScrollLock: true,
                                  }}
                                  label="Select Year From"
                                >
                                  {yearsfrom?.map((item, index) => {
                                    return (
                                      <MenuItem key={index} value={item.value}>
                                        {item.label ?? ""}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>

                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={3}>
                              <Tooltip
                                title={
                                  !searchQuery.yearsfrom
                                    ? "Select Year From First"
                                    : ""
                                }
                              >
                                <FormControl
                                  size="small"
                                  color="secondary"
                                  variant="outlined"
                                  className={classes.formControl}
                                >
                                  <InputLabel id="demo-simple-select-outlined-label">
                                    Select Year To
                                  </InputLabel>
                                  <Select
                                    disabled={!searchQuery.yearsfrom}
                                    name="yearsto"
                                    value={searchQuery.yearsto}
                                    onChange={handleSearchInputChange}
                                    onFocus={handleYearsTo}
                                    MenuProps={{
                                      disableScrollLock: true,
                                    }}
                                    label="Select Year To"
                                  >
                                    {yearsto?.map((item, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={item.value}
                                        >
                                          {item.value ?? ""}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Tooltip>
                            </Grid>
                            {/* <Grid item xs={12} sm={12} md={6} lg={3}>
                              <FormControl
                                size="small"
                                color="secondary"
                                variant="outlined"
                                className={classes.formControl}
                              >
                                <InputLabel id="demo-simple-select-outlined-label">
                                  Select Year
                                </InputLabel>
                                <Select
                                  name="years"
                                  value={searchQuery.years}
                                  onChange={handleSearchInputChange}
                                  onFocus={() => dispatch(fetYearsData())}
                                  MenuProps={{
                                    disableScrollLock: true,
                                  }}
                                  label="Select Year"
                                >
                                  {searchsArrayData?.map((item, index) => {
                                    return (
                                      <MenuItem key={index} value={item.value}>
                                        {item.label ?? ""}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid> */}
                          </div>
                        ) : (
                          <></>
                        );
                      })}

                    {/* {filter &&
                      Api === "archived_chart" &&
                      filter.map((x) => {
                        return x && x === "month" ? (
                          <>
                            <Grid item xs={12} sm={12} md={6} lg={3}>
                              <Tooltip
                                title={
                                  !searchQuery.year ? "Select Year First" : ""
                                }
                              >
                                <FormControl
                                  size="small"
                                  color="secondary"
                                  variant="outlined"
                                  className={classes.formControl}
                                >
                                  <InputLabel id="demo-simple-select-outlined-label">
                                    Select Month
                                  </InputLabel>
                                  <Select
                                    name="months"
                                    value={searchQuery.months}
                                    onChange={handleSearchInputChange}
                                    onFocus={handleMonths}
                                    MenuProps={{
                                      disableScrollLock: true,
                                    }}
                                    label="Select Month"
                                  >
                                    {months?.map((item, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={item.value}
                                        >
                                          {item.label ?? ""}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Tooltip>
                            </Grid>
                          </>
                        ) : (
                          <></>
                        );
                      })} */}

                    {/* <Grid item xs={12} sm={12} md={6} lg={3}>
                    <FormControl size="small" color="secondary" variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Chart Type</InputLabel>
                        <Select
                            value={highlightType}
                            onChange={(e) => {
                                setHighlightType(e.target.value)
                            }}
                            MenuProps={{
                                disableScrollLock: true
                            }}
                            label="Select Year"
                        >
                            <MenuItem value={0}>Show Highlights</MenuItem>
                            <MenuItem value={1}>Show All</MenuItem>
                        </Select>
                    </FormControl>

                </Grid> */}
                    {/* <Grid item xs={12} sm={12} md={6} lg={3}>
                <Button variant="contained" color="secondary">Filter</Button>
            </Grid> */}

                    <Grid item xs={12} sm={12} md={3} lg={2}>
                      <Button
                        className="clear-btn"
                        variant="contained"
                        onClick={resetSearchForm}
                      >
                        Clear
                      </Button>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <div className="dropdown">
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} sm={12} md={6} lg={3}>
                        <FormControl
                          size="small"
                          color="secondary"
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Select Filter
                          </InputLabel>
                          <Select
                            name="frequency"
                            value={state}
                            onChange={onValueChange}
                            MenuProps={{
                              disableScrollLock: true,
                            }}
                            label="Select Filter "
                          >
                            <MenuItem value="4">Yearly</MenuItem>
                            <MenuItem value="1">Monthly</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      {searchQuery && searchQuery.seriesType && searchQuery.seriesType === '2'? 
                      <>
                      <Grid item xs={12} sm={12} md={6} lg={3}>
                        <FormControl
                          size="small"
                          color="secondary"
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Select Geography
                          </InputLabel>
                          <Select
                            name="level"
                            value={searchQuery.level}
                            onChange={handleLevelChange}
                            MenuProps={{
                              disableScrollLock: true,
                            }}
                            label="Select Geography"
                          >
                            <MenuItem value="province">Province</MenuItem>
                            <MenuItem value="district">District</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      </>
                      :
                      <>
                      <Grid item xs={12} sm={12} md={6} lg={3}>
                        <FormControl
                          size="small"
                          color="secondary"
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Select Geography
                          </InputLabel>
                          <Select
                            name="geography"
                            value={geography}
                            onChange={handleGeographyChange}
                            MenuProps={{
                              disableScrollLock: true,
                            }}
                            label="Select Geography"
                          >
                            {" "}
                            {unit.map((element, index) => {
                              return (

                                <MenuItem key={index} value={element}>{element}</MenuItem>
                              );
                            })}
                            {/* <MenuItem value="Provincial">Province</MenuItem>
                            <MenuItem value="District">District</MenuItem> */}
                          </Select>
                        </FormControl>
                      </Grid>
                      </>
                      }

                      {geography && geography === "Provincial" ? (
                        <>
                          {filters &&
                            filters.map((x, i) => {
                              return x && x === "Provincial" ? (
                                <>
                                  <Grid item xs={12} sm={12} md={6} lg={3} key={i}>
                                    <FormControl
                                      size="small"
                                      color="secondary"
                                      variant="outlined"
                                      className={classes.formControl}
                                    >
                                      <InputLabel id="demo-simple-select-outlined-label">
                                        Select Provinces
                                      </InputLabel>
                                      <Select
                                        name="provinces"
                                        value={searchQuery.provinces}
                                        onChange={handleSearchInputChange}
                                        onFocus={handleProvincials}
                                        MenuProps={{
                                          disableScrollLock: true,
                                        }}
                                        label="Select Provinces"
                                      >
                                        {Provinces?.map((item, index) => {
                                          return (
                                            <MenuItem
                                              key={index}
                                              value={item.value}
                                            >
                                              {item.label ?? ""}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                </>
                              ) : (
                                <></>
                              );
                            })}
                        </>
                      ) : (
                        <></>
                      )}
                      {geography && geography === "District" ? (
                        <>
                          {filters &&
                            filters.map((x, i) => {
                              return x && x === "District" ? (
                                <>
                                  <Grid item xs={12} sm={12} md={6} lg={3} key={i}>
                                    <FormControl
                                      size="small"
                                      color="secondary"
                                      variant="outlined"
                                      className={classes.formControl}
                                    >
                                      <InputLabel id="demo-simple-select-outlined-label">
                                        Select Provinces
                                      </InputLabel>
                                      <Select
                                        name="provinces"
                                        value={geoprovince}
                                        onChange={handlegeoProvinceChange}
                                        onFocus={handleProvincials}
                                        MenuProps={{
                                          disableScrollLock: true,
                                        }}
                                        label="Select Provinces"
                                      >
                                        {Provinces?.map((item, index) => {
                                          return (
                                            <MenuItem
                                              key={index}
                                              value={item.value}
                                            >
                                              {item.label ?? ""}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={12} sm={12} md={6} lg={3}>
                                    <Tooltip
                                      title={
                                        !geoprovince
                                          ? "Select Province First"
                                          : ""
                                      }
                                    >
                                      <FormControl
                                        size="small"
                                        color="secondary"
                                        variant="outlined"
                                        className={classes.formControl}
                                      >
                                        <InputLabel id="demo-simple-select-outlined-label">
                                          Select District
                                        </InputLabel>
                                        <Select
                                          disabled={!geoprovince}
                                          name="districts"
                                          value={searchQuery.districts}
                                          onChange={handleSearchInputChange}
                                          onFocus={handleDistricts}
                                          MenuProps={{
                                            disableScrollLock: true,
                                          }}
                                          label="Select District"
                                        >
                                          {districts?.map((item, index) => {
                                            return (
                                              <MenuItem
                                                key={index}
                                                value={item.value}
                                              >
                                                {item.label ?? ""}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>
                                    </Tooltip>
                                  </Grid>
                                </>
                              ) : (
                                <></>
                              );
                            })}
                        </>
                      ) : (
                        <> </>
                      )}
                    </Grid>
                  </div>

                  {searchQuery && searchQuery.seriesType && searchQuery.seriesType === '1' ? 
                    <>
                    {state && state === "4" ? (
                    <>
                      {filter && filter[0].slice(-3) === "eng" ? (
                        <>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={12} md={6} lg={3}>

                              <FormControl
                                size="small"
                                color="secondary"
                                variant="outlined"
                                className={classes.formControl}
                              >
                                <InputLabel id="demo-simple-select-outlined-label">
                                  Select Year From
                                </InputLabel>
                                <Select
                                  name="filteryearengfrom"
                                  value={searchQuery.filteryearengfrom}
                                  onChange={handleSearchInputChange}
                                  onFocus={handleFilterYearengfrom}
                                  MenuProps={{
                                    disableScrollLock: true,
                                  }}
                                  label="Select Year From"
                                >
                                  {engYearfrom?.map((item, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={item.period_year}
                                      >
                                        {item.period_year ?? ""}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>

                            </Grid>
                            {type.subtab_name != "tab5" && (
                            <Grid item xs={12} sm={12} md={6} lg={3}>
                              <Tooltip
                                title={
                                  !searchQuery.filteryearengfrom
                                    ? "Select Year From First"
                                    : ""
                                }
                              >
                                <FormControl
                                  size="small"
                                  color="secondary"
                                  variant="outlined"
                                  className={classes.formControl}
                                >
                                  <InputLabel id="demo-simple-select-outlined-label">
                                    Select Year To
                                  </InputLabel>
                                  <Select
                                    disabled={!searchQuery.filteryearengfrom}
                                    name="filteryearengto"
                                    value={searchQuery.filteryearengto}
                                    onChange={handleSearchInputChange}
                                    onFocus={handleFilterYearengto}
                                    MenuProps={{
                                      disableScrollLock: true,
                                    }}
                                    label="Select Year To"
                                  >
                                    {engYearto?.map((item, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={item.period_year}
                                        >
                                          {item.period_year ?? ""}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Tooltip>
                            </Grid>
                            )}

                            {/* <Grid item xs={12} sm={12} md={6} lg={3}>
                                                <Tooltip title={!searchQuery.filteryearengfrom ? "Select Year From First" : ""}>
                                                    <FormControl size="small" color="secondary" variant="outlined" className={classes.formControl}>
                                                        <InputLabel id="demo-simple-select-outlined-label">Select Month From</InputLabel>
                                                        <Select
                                                            disabled={!searchQuery.filteryearengfrom}
                                                            name="filtermonthengfrom"
                                                            value={searchQuery.filtermonthengfrom}
                                                            onChange={handleSearchInputChange}
                                                            onFocus={handleFilterMonthengfrom}
                                                            MenuProps={{
                                                                disableScrollLock: true
                                                            }}
                                                            label="Select Filter"
                                                        >
                                                            {engMonthfrom?.map((item, index) => {

                                                                return (

                                                                    <MenuItem key={index} value={item.period}>
                                                                        {item.part ?? ''}
                                                                    </MenuItem>
                                                                );
                                                            })}


                                                        </Select>

                                                    </FormControl>
                                                </Tooltip>

                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={3}>
                                                <Tooltip title={!searchQuery.filteryearengto ? "Select Year To First" : ""}>
                                                    <FormControl size="small" color="secondary" variant="outlined" className={classes.formControl}>
                                                        <InputLabel id="demo-simple-select-outlined-label">Select Month To</InputLabel>
                                                        <Select
                                                            disabled={!searchQuery.filteryearengto}
                                                            name="filtermonthengto"
                                                            value={searchQuery.filtermonthengto}
                                                            onChange={handleSearchInputChange}
                                                            onFocus={handleFilterMonthengto}
                                                            MenuProps={{
                                                                disableScrollLock: true
                                                            }}
                                                            label="Select Filter"
                                                        >
                                                            {engMonthto?.map((item, index) => {
                                                                return (
                                                                    <MenuItem key={index} value={item.period}>
                                                                        {item.part ?? ''}
                                                                    </MenuItem>
                                                                );
                                                            })}


                                                        </Select>

                                                    </FormControl>
                                                </Tooltip>

                                            </Grid> */}

                            <Grid item xs={12} sm={12} md={3} lg={2} className="button-clear">
                              <Button
                                className="clear-btn"
                                variant="contained"
                                onClick={resetSearchForm}
                              >
                                Clear
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={12} md={6} lg={3}>
                              <Tooltip title="">
                                <FormControl
                                  size="small"
                                  color="secondary"
                                  variant="outlined"
                                  className={classes.formControl}
                                >
                                  <InputLabel id="demo-simple-select-outlined-label">
                                    Select Year From
                                  </InputLabel>
                                  <Select
                                    name="filteryearfrom"
                                    value={searchQuery.filteryearfrom}
                                    onChange={handleSearchInputChange}
                                    onFocus={handleFilterYearfrom}
                                    MenuProps={{
                                      disableScrollLock: true,
                                    }}
                                    label="Select Year From"
                                  >
                                    {FilterYearfrom?.map((item, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={item.period_year}
                                        >
                                          {item.period_year ?? ""}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Tooltip>
                            </Grid>
                            {type.subtab_name != "tab5" && (

                            <Grid item xs={12} sm={12} md={6} lg={3}>
                              <Tooltip
                                title={
                                  !searchQuery.filteryearfrom
                                    ? "Select Year From First"
                                    : ""
                                }
                              >
                                <FormControl
                                  size="small"
                                  color="secondary"
                                  variant="outlined"
                                  className={classes.formControl}
                                >
                                  <InputLabel id="demo-simple-select-outlined-label">
                                    Select Year To
                                  </InputLabel>
                                  <Select
                                    disabled={!searchQuery.filteryearfrom}
                                    name="filteryearto"
                                    value={searchQuery.filteryearto}
                                    onChange={handleSearchInputChange}
                                    onFocus={handleFilterYearto}
                                    MenuProps={{
                                      disableScrollLock: true,
                                    }}
                                    label="Select Year To"
                                  >
                                    {FilterYearto?.map((item, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={item.period_year}
                                        >
                                          {item.period_year ?? ""}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Tooltip>
                            </Grid>
                            )}

                            {/* <Grid item xs={12} sm={12} md={6} lg={3}>
                                                <Tooltip title={!searchQuery.filteryearfrom ? "Select Year From First" : ""}>
                                                    <FormControl size="small" color="secondary" variant="outlined" className={classes.formControl}>
                                                        <InputLabel id="demo-simple-select-outlined-label">Select Month From</InputLabel>
                                                        <Select
                                                            disabled={!searchQuery.filteryearfrom}
                                                            name="filtermonthfrom"
                                                            value={searchQuery.filtermonthfrom}
                                                            onChange={handleSearchInputChange}
                                                            onFocus={handleFilterMonthfrom}
                                                            MenuProps={{
                                                                disableScrollLock: true
                                                            }}
                                                            label="Select Filter"
                                                        >
                                                            {FilterMonthfrom?.map((item, index) => {

                                                                return (

                                                                    <MenuItem key={index} value={item.period}>
                                                                        {item.part ?? ''}
                                                                    </MenuItem>
                                                                );
                                                            })}


                                                        </Select>

                                                    </FormControl>
                                                </Tooltip>

                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={3}>
                                                <Tooltip title={!searchQuery.filteryearto ? "Select Year To First" : ""}>
                                                    <FormControl size="small" color="secondary" variant="outlined" className={classes.formControl}>
                                                        <InputLabel id="demo-simple-select-outlined-label">Select Month To</InputLabel>
                                                        <Select
                                                            disabled={!searchQuery.filteryearto}
                                                            name="filtermonthto"
                                                            value={searchQuery.filtermonthto}
                                                            onChange={handleSearchInputChange}
                                                            onFocus={handleFilterMonthto}
                                                            MenuProps={{
                                                                disableScrollLock: true
                                                            }}
                                                            label="Select Filter"
                                                        >
                                                            {FilterMonthto?.map((item, index) => {
                                                                return (
                                                                    <MenuItem key={index} value={item.period}>
                                                                        {item.part ?? ''}
                                                                    </MenuItem>
                                                                );
                                                            })}


                                                        </Select>

                                                    </FormControl>
                                                </Tooltip>

                                            </Grid> */}
                            <Grid item xs={12} sm={12} md={3} lg={2} className="button-clear">
                              <Button
                                className="clear-btn"
                                variant="contained"
                                onClick={resetSearchForm}
                              >
                                Clear
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}

                  {state && state === "1" ? (
                    <>
                      {filter && filter[0].slice(-3) === "eng" ? (
                        <>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={12} md={6} lg={3}>
                              <Tooltip>
                                <FormControl
                                  size="small"
                                  color="secondary"
                                  variant="outlined"
                                  className={classes.formControl}
                                >
                                  <InputLabel id="demo-simple-select-outlined-label">
                                    Select Year From
                                  </InputLabel>
                                  <Select
                                    name="filteryearengfrom"
                                    value={searchQuery.filteryearengfrom}
                                    onChange={handleSearchInputChange}
                                    onFocus={handleFilterYearengfrom}
                                    MenuProps={{
                                      disableScrollLock: true,
                                    }}
                                    label="Select Year From"
                                  >
                                    {engYearfrom?.map((item, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={item.period_year}
                                        >
                                          {item.period_year ?? ""}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Tooltip>
                            </Grid>

                            {/* <Grid item xs={12} sm={12} md={6} lg={3}>
                                                <Tooltip title={!searchQuery.filteryearengfrom ? "Select Year From First" : ""}>
                                                    <FormControl size="small" color="secondary" variant="outlined" className={classes.formControl}>
                                                        <InputLabel id="demo-simple-select-outlined-label">Select Year To</InputLabel>
                                                        <Select
                                                            name="filteryearengto"
                                                            value={searchQuery.filteryearengfrom}
                                                            onChange={handleSearchInputChange}
                                                            onFocus={handleFilterYearengto}
                                                            MenuProps={{
                                                                disableScrollLock: true
                                                            }}
                                                            label="Select Year To"
                                                        >
                                                            {engYearto?.map((item, index) => {
                                                                return (
                                                                    <MenuItem key={index} value={item.period_year}>
                                                                        {item.period_year ?? ''}
                                                                    </MenuItem>
                                                                );
                                                            })}


                                                        </Select>

                                                    </FormControl>
                                                </Tooltip>
                                            </Grid> */}

                            <Grid item xs={12} sm={12} md={6} lg={3}>
                              <Tooltip
                                title={
                                  !searchQuery.filteryearengfrom
                                    ? "Select Year From First"
                                    : ""
                                }
                              >
                                <FormControl
                                  size="small"
                                  color="secondary"
                                  variant="outlined"
                                  className={classes.formControl}
                                >
                                  <InputLabel id="demo-simple-select-outlined-label">
                                    Select Month From
                                  </InputLabel>
                                  <Select
                                    disabled={!searchQuery.filteryearengfrom}
                                    name="filtermonthengfrom"
                                    value={searchQuery.filtermonthengfrom}
                                    onChange={handleSearchInputChange}
                                    onFocus={handleFilterMonthengfrom}
                                    MenuProps={{
                                      disableScrollLock: true,
                                    }}
                                    label="Select Filter"
                                  >
                                    {engMonthfrom?.map((item, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={item.period}
                                        >
                                          {monthToString(item.part , 'en') ?? ""}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Tooltip>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={3}>
                              <Tooltip
                                title={
                                  !searchQuery.filteryearengto
                                    ? "Select Year To First"
                                    : ""
                                }
                              >
                                <FormControl
                                  size="small"
                                  color="secondary"
                                  variant="outlined"
                                  className={classes.formControl}
                                >
                                  <InputLabel id="demo-simple-select-outlined-label">
                                    Select Month To
                                  </InputLabel>
                                  <Select
                                    // disabled={!searchQuery.filteryearengto}
                                    name="filtermonthengto"
                                    value={searchQuery.filtermonthengto}
                                    onChange={handleSearchInputChange}
                                    onFocus={handleFilterMonthengto}
                                    MenuProps={{
                                      disableScrollLock: true,
                                    }}
                                    label="Select Filter"
                                  >
                                    {engMonthto?.map((item, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={item.period}
                                        >
                                          { monthToString(item.part , 'en')  ?? ""}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={2} className="button-clear">
                              <Button
                                className="clear-btn"
                                variant="contained"
                                onClick={resetSearchForm}
                              >
                                Clear
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={12} md={6} lg={3}>
                              <FormControl
                                size="small"
                                color="secondary"
                                variant="outlined"
                                className={classes.formControl}
                              >
                                <InputLabel id="demo-simple-select-outlined-label">
                                  Select Year From
                                </InputLabel>
                                <Select
                                  name="filteryearfrom"
                                  value={searchQuery.filteryearfrom}
                                  onChange={handleSearchInputChange}
                                  onFocus={handleFilterYearfrom}
                                  MenuProps={{
                                    disableScrollLock: true,
                                  }}
                                  label="Select Year From"
                                >
                                  {FilterYearfrom?.map((item, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={item.period_year}
                                      >
                                        {item.period_year ?? ""}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>

                            {/* <Grid item xs={12} sm={12} md={6} lg={3}>
                                                <Tooltip title={!searchQuery.filteryearfrom ? "Select Year From First" : ""}>
                                                    <FormControl size="small" color="secondary" variant="outlined" className={classes.formControl}>
                                                        <InputLabel id="demo-simple-select-outlined-label">Select Year To</InputLabel>
                                                        <Select
                                                            name="filteryearto"
                                                            value={searchQuery.filteryearfrom}
                                                            onChange={handleSearchInputChange}
                                                            onFocus={handleFilterYearto}
                                                            MenuProps={{
                                                                disableScrollLock: true
                                                            }}
                                                            label="Select Year To"
                                                        >
                                                            {FilterYearto?.map((item, index) => {
                                                                return (
                                                                    <MenuItem key={index} value={item.period_year}>
                                                                        {item.period_year ?? ''}
                                                                    </MenuItem>
                                                                );
                                                            })}


                                                        </Select>

                                                    </FormControl>
                                                </Tooltip>
                                            </Grid> */}

                            <Grid item xs={12} sm={12} md={6} lg={3}>
                              <Tooltip
                                title={
                                  !searchQuery.filteryearfrom
                                    ? "Select Year From First"
                                    : ""
                                }
                              >
                                <FormControl
                                  size="small"
                                  color="secondary"
                                  variant="outlined"
                                  className={classes.formControl}
                                >
                                  <InputLabel id="demo-simple-select-outlined-label">
                                    Select Month From
                                  </InputLabel>
                                  <Select
                                    disabled={!searchQuery.filteryearfrom}
                                    name="filtermonthfrom"
                                    value={searchQuery.filtermonthfrom}
                                    onChange={handleSearchInputChange}
                                    onFocus={handleFilterMonthfrom}
                                    MenuProps={{
                                      disableScrollLock: true,
                                    }}
                                    label="Select Filter"
                                  >
                                    {FilterMonthfrom?.map((item, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={item.period}
                                        >
                                          {monthToString(item.part )  ?? ""}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Tooltip>
                            </Grid>
                            {type.subtab_name != "tab5" && (
                              <Grid item xs={12} sm={12} md={6} lg={3}>
                                <FormControl
                                  size="small"
                                  color="secondary"
                                  variant="outlined"
                                  className={classes.formControl}
                                >
                                  <InputLabel id="demo-simple-select-outlined-label">
                                    Select Month To
                                  </InputLabel>
                                  <Select
                                    // disabled={!searchQuery.filteryearfrom}
                                    name="filtermonthto"
                                    value={searchQuery.filtermonthto}
                                    onChange={handleSearchInputChange}
                                    onFocus={handleFilterMonthto}
                                    MenuProps={{
                                      disableScrollLock: true,
                                    }}
                                    label="Select Filter"
                                  >
                                    {FilterMonthto?.map((item, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={item.period}
                                        >
                                          {monthToString(item.part) ?? ""}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Grid>
                            )}

                            <Grid item xs={12} sm={12} md={3} lg={2}>
                              <Button
                                className="clear-btn"
                                variant="contained"
                                onClick={resetSearchForm}
                              >
                                Clear
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                    </>
                    :
                    <>
                   {state && state === "4" ? (
                    <>
                      {filter && filter[0].slice(-3) === "eng" ? (
                        <>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={12} md={6} lg={3}>

                              <FormControl
                                size="small"
                                color="secondary"
                                variant="outlined"
                                className={classes.formControl}
                              >
                                <InputLabel id="demo-simple-select-outlined-label">
                                  Select Year 
                                </InputLabel>
                                <Select
                                  name="year"
                                  value={searchQuery.year}
                                  onChange={handleSearchInputChange}
                                  onFocus={handleFilterYearengfrom}
                                  MenuProps={{
                                    disableScrollLock: true,
                                  }}
                                  label="Select Year "
                                >
                                  {engYearfrom?.map((item, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={item.period_year}
                                      >
                                        {item.period_year ?? ""}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>

                            </Grid>

                           

                            {/* <Grid item xs={12} sm={12} md={6} lg={3}>
                                                <Tooltip title={!searchQuery.filteryearengfrom ? "Select Year From First" : ""}>
                                                    <FormControl size="small" color="secondary" variant="outlined" className={classes.formControl}>
                                                        <InputLabel id="demo-simple-select-outlined-label">Select Month From</InputLabel>
                                                        <Select
                                                            disabled={!searchQuery.filteryearengfrom}
                                                            name="filtermonthengfrom"
                                                            value={searchQuery.filtermonthengfrom}
                                                            onChange={handleSearchInputChange}
                                                            onFocus={handleFilterMonthengfrom}
                                                            MenuProps={{
                                                                disableScrollLock: true
                                                            }}
                                                            label="Select Filter"
                                                        >
                                                            {engMonthfrom?.map((item, index) => {

                                                                return (

                                                                    <MenuItem key={index} value={item.period}>
                                                                        {item.part ?? ''}
                                                                    </MenuItem>
                                                                );
                                                            })}


                                                        </Select>

                                                    </FormControl>
                                                </Tooltip>

                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={3}>
                                                <Tooltip title={!searchQuery.filteryearengto ? "Select Year To First" : ""}>
                                                    <FormControl size="small" color="secondary" variant="outlined" className={classes.formControl}>
                                                        <InputLabel id="demo-simple-select-outlined-label">Select Month To</InputLabel>
                                                        <Select
                                                            disabled={!searchQuery.filteryearengto}
                                                            name="filtermonthengto"
                                                            value={searchQuery.filtermonthengto}
                                                            onChange={handleSearchInputChange}
                                                            onFocus={handleFilterMonthengto}
                                                            MenuProps={{
                                                                disableScrollLock: true
                                                            }}
                                                            label="Select Filter"
                                                        >
                                                            {engMonthto?.map((item, index) => {
                                                                return (
                                                                    <MenuItem key={index} value={item.period}>
                                                                        {item.part ?? ''}
                                                                    </MenuItem>
                                                                );
                                                            })}


                                                        </Select>

                                                    </FormControl>
                                                </Tooltip>

                                            </Grid> */}

                            <Grid item xs={12} sm={12} md={3} lg={2} className="button-clear">
                              <Button
                                className="clear-btn"
                                variant="contained"
                                onClick={resetSearchForm}
                              >
                                Clear
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={12} md={6} lg={3}>
                              <Tooltip>
                                <FormControl
                                  size="small"
                                  color="secondary"
                                  variant="outlined"
                                  className={classes.formControl}
                                >
                                  <InputLabel id="demo-simple-select-outlined-label">
                                    Select Year 
                                  </InputLabel>
                                  <Select
                                    name="year"
                                    value={searchQuery.year}
                                    onChange={handleSearchInputChange}
                                    onFocus={handleFilterYearfrom}
                                    MenuProps={{
                                      disableScrollLock: true,
                                    }}
                                    label="Select Year "
                                  >
                                    {FilterYearfrom?.map((item, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={item.period_year}
                                        >
                                          {item.period_year ?? ""}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Tooltip>
                            </Grid>

                            

                            {/* <Grid item xs={12} sm={12} md={6} lg={3}>
                                                <Tooltip title={!searchQuery.filteryearfrom ? "Select Year From First" : ""}>
                                                    <FormControl size="small" color="secondary" variant="outlined" className={classes.formControl}>
                                                        <InputLabel id="demo-simple-select-outlined-label">Select Month From</InputLabel>
                                                        <Select
                                                            disabled={!searchQuery.filteryearfrom}
                                                            name="filtermonthfrom"
                                                            value={searchQuery.filtermonthfrom}
                                                            onChange={handleSearchInputChange}
                                                            onFocus={handleFilterMonthfrom}
                                                            MenuProps={{
                                                                disableScrollLock: true
                                                            }}
                                                            label="Select Filter"
                                                        >
                                                            {FilterMonthfrom?.map((item, index) => {

                                                                return (

                                                                    <MenuItem key={index} value={item.period}>
                                                                        {item.part ?? ''}
                                                                    </MenuItem>
                                                                );
                                                            })}


                                                        </Select>

                                                    </FormControl>
                                                </Tooltip>

                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={3}>
                                                <Tooltip title={!searchQuery.filteryearto ? "Select Year To First" : ""}>
                                                    <FormControl size="small" color="secondary" variant="outlined" className={classes.formControl}>
                                                        <InputLabel id="demo-simple-select-outlined-label">Select Month To</InputLabel>
                                                        <Select
                                                            disabled={!searchQuery.filteryearto}
                                                            name="filtermonthto"
                                                            value={searchQuery.filtermonthto}
                                                            onChange={handleSearchInputChange}
                                                            onFocus={handleFilterMonthto}
                                                            MenuProps={{
                                                                disableScrollLock: true
                                                            }}
                                                            label="Select Filter"
                                                        >
                                                            {FilterMonthto?.map((item, index) => {
                                                                return (
                                                                    <MenuItem key={index} value={item.period}>
                                                                        {item.part ?? ''}
                                                                    </MenuItem>
                                                                );
                                                            })}


                                                        </Select>

                                                    </FormControl>
                                                </Tooltip>

                                            </Grid> */}
                            <Grid item xs={12} sm={12} md={3} lg={2} className="button-clear">
                              <Button
                                className="clear-btn"
                                variant="contained"
                                onClick={resetSearchForm}
                              >
                                Clear
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}

                  {state && state === "1" ? (
                    <>
                      {filter && filter[0].slice(-3) === "eng" ? (
                        <>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={12} md={6} lg={3}>
                              <Tooltip>
                                <FormControl
                                  size="small"
                                  color="secondary"
                                  variant="outlined"
                                  className={classes.formControl}
                                >
                                  <InputLabel id="demo-simple-select-outlined-label">
                                    Select Year From
                                  </InputLabel>
                                  <Select
                                    name="year"
                                    value={searchQuery.year}
                                    onChange={handleSearchInputChange}
                                    onFocus={handleFilterYearengfrom}
                                    MenuProps={{
                                      disableScrollLock: true,
                                    }}
                                    label="Select Year "
                                  >
                                    {engYearfrom?.map((item, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={item.period_year}
                                        >
                                          {item.period_year ?? ""}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Tooltip>
                            </Grid>

                            {/* <Grid item xs={12} sm={12} md={6} lg={3}>
                                                <Tooltip title={!searchQuery.filteryearengfrom ? "Select Year From First" : ""}>
                                                    <FormControl size="small" color="secondary" variant="outlined" className={classes.formControl}>
                                                        <InputLabel id="demo-simple-select-outlined-label">Select Year To</InputLabel>
                                                        <Select
                                                            name="filteryearengto"
                                                            value={searchQuery.filteryearengfrom}
                                                            onChange={handleSearchInputChange}
                                                            onFocus={handleFilterYearengto}
                                                            MenuProps={{
                                                                disableScrollLock: true
                                                            }}
                                                            label="Select Year To"
                                                        >
                                                            {engYearto?.map((item, index) => {
                                                                return (
                                                                    <MenuItem key={index} value={item.period_year}>
                                                                        {item.period_year ?? ''}
                                                                    </MenuItem>
                                                                );
                                                            })}


                                                        </Select>

                                                    </FormControl>
                                                </Tooltip>
                                            </Grid> */}

                            <Grid item xs={12} sm={12} md={6} lg={3}>
                              <Tooltip
                                title={
                                  !searchQuery.year
                                    ? "Select Year First"
                                    : ""
                                }
                              >
                                <FormControl
                                  size="small"
                                  color="secondary"
                                  variant="outlined"
                                  className={classes.formControl}
                                >
                                  <InputLabel id="demo-simple-select-outlined-label">
                                    Select Month 
                                  </InputLabel>
                                  <Select
                                    disabled={!searchQuery.year}
                                    name="month"
                                    value={searchQuery.month}
                                    onChange={handleSearchInputChange}
                                    onFocus={handleMontheng}
                                    MenuProps={{
                                      disableScrollLock: true,
                                    }}
                                    label="Select Filter"
                                  >
                                    {montheng?.map((item, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={item.part}
                                        >
                                          {item.part ?? ""}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Tooltip>
                            </Grid>

                           
                            <Grid item xs={12} sm={12} md={3} lg={2} className="button-clear">
                              <Button
                                className="clear-btn"
                                variant="contained"
                                onClick={resetSearchForm}
                              >
                                Clear
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={12} md={6} lg={3}>
                              <FormControl
                                size="small"
                                color="secondary"
                                variant="outlined"
                                className={classes.formControl}
                              >
                                <InputLabel id="demo-simple-select-outlined-label">
                                  Select Year 
                                </InputLabel>
                                <Select
                                  name="year"
                                  value={searchQuery.year}
                                  onChange={handleSearchInputChange}
                                  onFocus={handleFilterYearfrom}
                                  MenuProps={{
                                    disableScrollLock: true,
                                  }}
                                  label="Select Year"
                                >
                                  {FilterYearfrom?.map((item, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={item.period_year}
                                      >
                                        {item.period_year ?? ""}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>

                            {/* <Grid item xs={12} sm={12} md={6} lg={3}>
                                                <Tooltip title={!searchQuery.filteryearfrom ? "Select Year From First" : ""}>
                                                    <FormControl size="small" color="secondary" variant="outlined" className={classes.formControl}>
                                                        <InputLabel id="demo-simple-select-outlined-label">Select Year To</InputLabel>
                                                        <Select
                                                            name="filteryearto"
                                                            value={searchQuery.filteryearfrom}
                                                            onChange={handleSearchInputChange}
                                                            onFocus={handleFilterYearto}
                                                            MenuProps={{
                                                                disableScrollLock: true
                                                            }}
                                                            label="Select Year To"
                                                        >
                                                            {FilterYearto?.map((item, index) => {
                                                                return (
                                                                    <MenuItem key={index} value={item.period_year}>
                                                                        {item.period_year ?? ''}
                                                                    </MenuItem>
                                                                );
                                                            })}


                                                        </Select>

                                                    </FormControl>
                                                </Tooltip>
                                            </Grid> */}

                            <Grid item xs={12} sm={12} md={6} lg={3}>
                              <Tooltip
                                title={
                                  !searchQuery.year
                                    ? "Select Year"
                                    : ""
                                }
                              >
                                <FormControl
                                  size="small"
                                  color="secondary"
                                  variant="outlined"
                                  className={classes.formControl}
                                >
                                  <InputLabel id="demo-simple-select-outlined-label">
                                    Select Month 
                                  </InputLabel>
                                  <Select
                                    disabled={!searchQuery.year}
                                    name="month"
                                    value={searchQuery.month}
                                    onChange={handleSearchInputChange}
                                    onFocus={handleMonth}
                                    MenuProps={{
                                      disableScrollLock: true,
                                    }}
                                    label="Select Month"
                                  >
                                    {month?.map((item, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={item.part}
                                        >
                                          {item.part ?? ""}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Tooltip>
                            </Grid>
                           

                            <Grid item xs={12} sm={12} md={3} lg={2}>
                              <Button
                                className="clear-btn"
                                variant="contained"
                                onClick={resetSearchForm}
                              >
                                Clear
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                    </>
                  }

                  
                </>
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Grid item xs={12} sm={12} md={6} lg={3} className={`radio-level ${classes.radioLevel}`} >
          <FormControl className="row">
            {/* <FormLabel id="chartLevel">
                Level
              </FormLabel> */}
            <RadioGroup
              row
              aria-labelledby="chartLevel"
              name="chartLevel"
              value={searchQuery.chartLevel}
              onChange={handleSearchInputChange}
            >
              <FormControlLabel
                value="toplevel"
                control={<Radio />}
                label="Top Level"
              />
              <FormControlLabel
                value="expertlevel"
                control={<Radio />}
                label="Expert Level"
              />
            </RadioGroup>
          </FormControl>
         <div style={{display:"flex",marginLeft:"40px"}}><span> Note: The data displayed on the dashboard pertains to the period from Baishakh to Chaitra.</span></div>
        </Grid>
      </div>
    </div>
  );
};

export default Search;