import { Card, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PreviewChartCard from "../../components/PreviewChartCard";
import PreviewTable from "../../components/table"
import axiosRequest from "../../utils/axiosRequest";
import {
  HorizontalBarConfigs,
  lineConfigs,
  VerticalBarConfigs,
  labelOptions,
  horizontalLabelOptions
} from "../../utils/lineSettings";
import { pieChartConfig } from "../../utils/pieChartConfig";
import Loader from "../SmallLoader";
import { ControlPointSharp } from "@material-ui/icons";
/*
  item = tab data,
  api = 
  type = 
*/
const ChartContainer = ({ item, searchQuery, theme, Api, type, index }) => {
  const [chartWidth, setChartWidth] = useState(6);

  const [chartLoader, setChartLoader] = useState(true);

  const [chartConfig, setChartConfig] = useState({});

  const [chartData, setChartData] = useState({});

  const [chartType, setChartType] = useState({});


  // console.log('tab data item ', item )
  // console.log('tab data api ', Api )
  // console.log('tab data type ', type )


  /***
   * GET VALUES FROM API FOR INDIVIDUAL CHART AND SET CHART CONFIG
   */

  //  console.log("series",chartData)

  const nepaliMonths = {
    "01": "Baishakh",
    "02": "Jestha",
    "03": "Ashadh",
    "04": "Shrawan",
    "05": "Bhadra",
    "06": "Ashwin",
    "07": "Kartik",
    "08": "Mangsir",
    "09": "Poush",
    "10": "Magh",
    "11": "Falgun",
    "12": "Chaitra"
  };

  useEffect(() => {
    if (type?.graph === "bar") {
      setChartType("bar");
    } else if (type?.graph === "pie") {
      setChartType("pie");
    } else if (type?.graph === "horizontal_stacked_bar") {
      setChartType("horizontal_stacked_bar");
    } else if (type?.graph === "tabular") {
      setChartType("tabular");

    }
    else {
      setChartType("line");
    }

  }, [type]);

  useEffect(() => {
    if (item && item.Slug) {
      setChartLoader(true);

      /**
       * Fetch new chart data whenever there is change in searchQuery
       */

      if (Api === "chartconfig") {
        let search_params = {
          yearfrom: '',
          yearto: '',
          province: '',
          district: '',
          frequency: '',
          seriesType: '',
          level: '',
          year: '',
          month: ''
        };
        if (searchQuery.frequency === '1') {
          if (searchQuery.filteryearfrom) {
            search_params.yearto = searchQuery.filteryearfrom;
            searchQuery.filteryearto = searchQuery.filteryearfrom;
          }
          else if (searchQuery.filteryearengfrom) {
            search_params.yearto = searchQuery.filteryearengfrom;
            searchQuery.filteryearengto = searchQuery.filteryearengfrom;
          }
          //filter to and from date are same for tab 5
          if (type.subtab_name === "tab5") {
            console.log('search query', searchQuery)
            if (searchQuery.filtermonthfrom) {
              // search_params.yearto = searchQuery.filtermonthfrom;
              searchQuery.filtermonthto = searchQuery.filtermonthfrom;

            }
            if (searchQuery.filtermonthengfrom) {
              // search_params.yearto = searchQuery.filtermonthengfrom;
              searchQuery.filtermonthengto = searchQuery.filtermonthengfrom;
            }
          }
        } else {
          if (type.subtab_name === "tab5") {
            if (searchQuery.filteryearfrom) {
              searchQuery.filteryearto = searchQuery.filteryearfrom;
            }
          }

        }
        if (searchQuery.level) {
          search_params.level = searchQuery.level;
        }
        if (searchQuery.year) {
          search_params.year = searchQuery.year;
        }
        if (searchQuery.month) {
          if (searchQuery.month > 9) {
            search_params.month = searchQuery.month;
          }
          else {
            search_params.month = '0' + searchQuery.month;
          }
        }
        if (searchQuery.filteryearfrom) {
          search_params.yearfrom = searchQuery.filteryearfrom;
        }
        if (searchQuery.filteryearengfrom) {
          search_params.yearfrom = searchQuery.filteryearengfrom;
        }
        if (searchQuery.filteryearto) {
          search_params.yearto = searchQuery.filteryearto
        }
        if (searchQuery.filteryearengto) {
          search_params.yearto = searchQuery.filteryearengto
        }
        if (searchQuery.filtermonthfrom) {
          search_params.yearfrom = searchQuery.filtermonthfrom
        }
        if (searchQuery.filtermonthto) {
          search_params.yearto = searchQuery.filtermonthto
        }
        if (searchQuery.filtermonthengfrom) {
          search_params.yearfrom = searchQuery.filtermonthengfrom
        }
        if (searchQuery.filtermonthengto) {
          search_params.yearto = searchQuery.filtermonthengto
        }
        if (searchQuery.districts) {
          search_params.district = searchQuery.districts
        }
        if (searchQuery.frequency) {
          search_params.frequency = searchQuery.frequency;
        }
        if (searchQuery.provinces) {
          search_params.province = searchQuery.provinces;
        }
        if (searchQuery.seriesType) {
          search_params.seriesType = searchQuery.seriesType;
        }


        // let requestUrl = `${process.env.REACT_APP_MAIN_URL}${Api}/${
        //   item.Slug
        // }/${
        //   searchQuery.filteryearfrom
        //     ? `?yearfrom=${
        //         searchQuery.filteryearfrom +
        //         "" +
        //         String(searchQuery.filtermonthfrom).slice(-2)
        //       }${
        //         searchQuery.filteryearto
        //           ? "&yearto=" +
        //             searchQuery.filteryearto +
        //             String(searchQuery.filtermonthto).slice(-2)
        //           : ""
        //       }`
        //     : `${
        //         searchQuery.provinces
        //           ? `?province=${searchQuery.provinces}${
        //               searchQuery.districts
        //                 ? "&district=" + searchQuery.districts
        //                 : ""
        //             }`
        //           : ``
        //       }`
        // }`;
        console.log("${process.env.REACT_APP_MAIN_URL}${Api}/${item.Slug}/", "url")
        axiosRequest.get(`${process.env.REACT_APP_MAIN_URL}${Api}/${item.Slug}/`, { params: search_params })
          // axiosRequest.get(`${process.env.REACT_APP_MAIN_URL}${Api}/${modifiedSlug}/`, { params: search_params })
          .then((res) => {
            // console.log("result",Object.keys(chartConfig).length === 0)
            // console.log("results",res.data.islive != "1")
            if (res.data.data) {
              let test = [];
              for (let x in res.data.data) {
                let obj = res.data.data[x];
                for (let y of obj) {
                  for (let z of y) {
                    z.stack = x;
                    test.push(z);
                  }
                }
              }


              const stacktest = test
                .sort((a, b) => parseInt(a.ordering) - parseInt(b.ordering)) // Sort the data based on the ordering value
                .map((y, index) => {

                  let obj = { ...y };
                  obj.index = index + 1; // Setting the index starting from 1

                  if (y?.value && y?.value instanceof Array) {
                    const sortedData = y.value.sort((a, b) =>
                      a.name?.id > b.name?.id ? -1 : 1
                    );

                    if (sortedData) {
                      obj.values = sortedData.map(item => {
                        let newName = item.name;
                        if (newName.toString().length > 4) {
                          res.data.headerName = `${res.data.title}(${item.name.slice(0, 4)})`
                        }
                        if (newName.toString().length > 4) { // Check if length is greater than 4
                          let monthCode = newName.slice(-2); // Extracting the month code
                          if (nepaliMonths.hasOwnProperty(monthCode)) {
                            newName = nepaliMonths[monthCode];
                          }
                        }


                        return { ...item, name: newName };
                      });

                      obj.tooltipName = y.name ? y.name : y.mappedName;
                      obj.type = y.charttype ? y.charttype : "";

                      if (['none', 'pie', 'pieChart'].includes(obj.type)) {
                        if (sortedData.length > 0) {
                          obj.values = [sortedData[sortedData.length - 1]];
                        } else {
                          obj.values = [];
                        }
                      }
                      return obj;
                    }

                    // if (sortedData) {
                    //   obj.values = sortedData; // set new object values inside object
                    //   obj.tooltipName = y.name ? y.name : y.mappedName; // set tooltip and table header name
                    //   // obj.stack = y?.stack;
                    //   obj.type = y.charttype ? y.charttype : "";

                    //   if (['none', 'pie', 'pieChart'].includes(obj.type)) {
                    //     if(sortedData.length > 0){
                    //       obj.values = [sortedData[sortedData.length - 1]]; // set only the last item
                    //     }
                    //    else{
                    //     obj.values = []
                    //    }
                    //   }

                    //   return obj;
                    // }
                  }
                });


              let newItem = { ...item };
              newItem.chartTitle = res.data?.title;
              newItem.headerName = res.data?.headerName;
              if ((res.data.islive != "1" || Object.keys(chartConfig).length === 0)) {

                newItem.obj.data = stacktest;

              }
              // newItem.x_axis_name = item.x_axis_name
              // newItem.y_axis_name = item.y_axis_name
              console.log("new", newItem)
              setChartConfig(newItem);
              setChartLoader(false);
            }
          })

          .catch((err) => {
            console.log("error", err);
          });
      }
      else {

        let search_param = {
          yearfrom: '',
          yearto: '',
        };

        if (searchQuery.yearsfrom) {
          search_param.yearfrom = searchQuery.yearsfrom;
        }

        if (searchQuery.yearsto) {
          search_param.yearto = searchQuery.yearsto
        }

        axiosRequest.get(`${process.env.REACT_APP_MAIN_URL}api/${Api}/${item.Slug}`, { params: search_param })

          // let requestUrl = `${process.env.REACT_APP_MAIN_URL}api/${Api}/${item.Slug}${searchQuery?.years ?`?year=${searchQuery.years} `: "" 
          //   }`;

          // axiosRequest
          //   .get(requestUrl)
          .then((res) => {

            if (res.data.data.nogroup.length > 0) {
              if (res.data && res.data.data) {
                const newData = res.data.data.nogroup.map((y) => {
                  /**
                   * To create new modified object
                   */
                  let obj = { ...y };

                  /**
                   * find data that has same name and append to original object
                   */
                  // const foundObj = res.data.data?.find(x => x.name === y.name)
                  // console.log("found",foundObj)
                  if (y?.value && y?.value instanceof Array) {

                    const sortedData = y.value.sort((a, b) =>
                      a.name?.id > b.name?.id ? -1 : 1
                    );

                    if (sortedData) {

                      // obj.values = sortedData; // set new object values inside object
                      obj.values = sortedData.map(item => {
                        let newName = item.name;
                        if (newName.toString().length > 4) { // Check if length is greater than 4
                          let monthCode = newName.slice(-2); // Extracting the month code
                          if (nepaliMonths.hasOwnProperty(monthCode)) {
                            newName = newName.slice(0, 4) + "-" + nepaliMonths[monthCode];
                          }
                        }
                        return { ...item, name: newName };
                      });

                      obj.tooltipName = y.name ? y.name : y.mappedName; //set tooltip and table header name
                      return obj;
                    }
                  }
                  //else {
                  //     if (y) {
                  //         obj.values = foundObj.value // set new object values inside object
                  //         obj.tooltipName = foundObj?.mappedName //set tooltip and table header name
                  //         return obj
                  //     }
                  // }
                });
                let newItem = { ...res.data };
                newItem.data.nogroup = newData;
                newItem.chartTitle = res.data?.title;
                // newItem.x_axis_name = item.x_axis_name
                // newItem.y_axis_name = item.y_axis_name
                setChartConfig(newItem);
                setChartLoader(false);
              }
            }
          })

          .catch((err) => {
            console.log("error", err);
          });
      }
    }
  }, [item, searchQuery]);

  /***
 * GET VERTICAL OR HORIZONTAL CHART BASED ON LEGHT OF ARRAY
 */

  const handleBarType = (dataLength) => {
    // console.log(chartConfig)

    /**
     * To dynamically set the width of the chart container.
     * if less than 10 then show 2 columns per row else set 1 column per row.
     */
    if (dataLength > 20) {
      setChartWidth(20);
    } else {
      setChartWidth(10);
    }


    /**
     * Set bat configs based on width of chart container
     */
    let newChartData = { ...VerticalBarConfigs };
    // let newChartData = { ...groupedBarChart }
    /**
     * Create array of legends from data received
     */
    // console.log( 'chartConfig', newChartData)
    if (chartConfig.obj) {
      //   newChartData.legend.data = chartConfig.obj.data?.map(
      //     (x) => x.tooltipName
      //   );
      let timeSeries = [];

      chartConfig.obj.data?.map((x) => {
        if (x.values instanceof Array) {
          x.values.forEach(element => {
            console.log("data here", element)
            let i = timeSeries.findIndex(el => el == element.name);
            if (i == -1) {
              timeSeries.push(element.name);
            }
          });
        }

        // if (x.values instanceof Array) {
        //   x.values.forEach(element => {
        //     let i = timeSeries.findIndex(el => el == element.name);
        //     if (i == -1) {
        //       if (element.name.toString().length <= 4) {
        //         timeSeries.push(element.name);
        //       } else {
        //         let newName = element.name;
        //         let monthCode = newName.slice(-2); // Extracting the month code
        //         if (nepaliMonths.hasOwnProperty(monthCode)) {
        //           newName = newName.slice(0,4)+ "-" +nepaliMonths[monthCode] ;
        //         }
        //         console.log("name",newName)
        //         timeSeries.push(newName);
        //       }
        //     }
        //   });
        // }

      })
      if (timeSeries.length > 0) {
        timeSeries = timeSeries.sort()
      }
      let seriesData = chartConfig.obj.data?.map((x) => {

        let mainObj = {
          type: x.type ? x.type === "none" ? "bar" : x.type : "bar", //chart type
          // stack: x.stack === "nogroup" || x.stack === "" ? "" : x.stack ,
          // type: "bar", //chart type
          // stack: x.stack ? x.stack : "",
          name: x.tooltipName, // value that is shown on tooltip when hovered
          label: labelOptions,
          emphasis: {
            focus: 'series'
          },
          // barGap : '0%',

        };


        if (x.values) {
          /**
           * Set axis of chart based on length of data or chart container
           */
          let arr = [];
          if (x.values instanceof Array) {

            timeSeries.forEach(element => {
              let i = x.values.findIndex(el => el.name == element);
              if (i != -1) {
                arr.push(x.values[i].value);
              } else {
                arr.push(0)
              }
            });
          }
          newChartData[dataLength > 40 ? "yAxis" : "xAxis"] = {
            type: "category",
            data:
              x.values instanceof Array
                ? x.values?.map((y) => {
                  return y.name;
                })
                : [],
            axisLabel: {
              show: dataLength < 20,
              interval: 0, //show all names / donot skip any labels
              rotate: 60, // rotate albels by 30 degree
              formatter: function (name) {
                console.log(seriesData);
                const twoLines = name.replace(" ", "\n");
                const shortName = name.substring(0, 15); //show only 10 strinh from name
                return dataLength > 10 ? shortName : twoLines;
              },
              textStyle: {
                fontSize: 11,
              },
            },
            name: item.x_axis_name,
            nameLocation: "middle",
            nameGap: dataLength > 40 ? 60 : 40,
          };
          newChartData[dataLength > 40 ? "xAxis" : "yAxis"] = [
            {
              type: "value",
              name: item.y_axis_name,
              nameLocation: "middle",
              nameGap: 60,
              axisLabel: {
                formatter: function (value) {
                  if (value >= 1000000000) {
                    return (value / 1000000000).toFixed(0) + 'B';
                  } else if (value >= 1000000) {
                    return (value / 1000000).toFixed(0) + 'M';
                  } else if (value >= 1000) {
                    return (value / 1000).toFixed(0) + 'K';
                  } else {
                    return value;
                  }
                },
                textStyle: {
                  fontSize: 11,
                },
                // rotate : 20
              }
            },
          ];
          mainObj.data =
            x.values && x.values instanceof Array
              ? arr
              : [];

        }
        return mainObj;
      });
      // console.log( 'series data', seriesData)
      newChartData.series = seriesData; // add data transformed above to series key on main chart object. Main chart object is based on echarts

      return newChartData;
    } else {
      //   newChartData.legend.data = chartConfig.data.nogroup?.map(
      //     (x) => x.tooltipName
      //   );

      let timeSeries = [];

      chartConfig.data.nogroup?.map((x) => {
        if (x.values instanceof Array) {
          x.values.forEach(element => {
            console.log("data", element)
            let i = timeSeries.findIndex(el => el == element.name);
            if (i == -1) {
              timeSeries.push(element.name);
            }
          });
        }
      })
      if (timeSeries.length > 0) {
        timeSeries = timeSeries.sort()
      }


      let seriesData = chartConfig.data.nogroup?.map((x) => {

        let mainObj = {
          type: x.type ? x.type === "none" ? "bar" : x.type : "bar", //chart type
          //  stack: x.stack ? x.stack : "",
          // stack: x.stack ? x.stack : "",
          name: x.tooltipName, // value that is shown on tooltip when hovered
          label: labelOptions,
          emphasis: {
            focus: 'series'
          },
          emphasis: {
            focus: 'series'
          },
          barGap: '10%'
        };

        if (x.values) {
          /**
           * Set axis of chart based on length of data or chart container
           */

          let arr = [];
          if (x.values instanceof Array) {

            timeSeries.forEach(element => {
              let i = x.values.findIndex(el => el.name == element);
              if (i != -1) {
                arr.push(x.values[i].value);
              } else {
                arr.push(0)
              }
            });
          }


          newChartData[dataLength > 40 ? "yAxis" : "xAxis"] = {
            type: "category",
            data:
              x.values instanceof Array
                ? x.values?.map((y) => {
                  return y.name;
                })
                : [],
            axisLabel: {
              show: dataLength < 20,
              interval: 0, //show all names / donot skip any labels
              rotate: 30, // rotate albels by 30 degree
              formatter: function (name) {
                const twoLines = name.replace(" ", "\n");
                const shortName = name.substring(0, 15); //show only 10 strinh from name
                return dataLength > 10 ? shortName : twoLines;
              },
              textStyle: {
                fontSize: 10,
              },
            },
            name: item.x_axis_name,
            nameLocation: "middle",
            nameGap: dataLength > 40 ? 100 : 50,
          };
          newChartData[dataLength > 40 ? "xAxis" : "yAxis"] = [
            {
              type: "value",
              name: item.y_axis_name,
              nameLocation: "middle",
              nameGap: 60,
            },
          ];
          mainObj.data =
            x.values && x.values instanceof Array
              ? arr
              : [];
        }

        return mainObj;
      });
      // console.log( seriesData , 'series data');
      newChartData.series = seriesData; // add data transformed above to series key on main chart object. Main chart object is based on echarts

      return newChartData;
    }
  };

  const handleHBarType = (dataLength) => {
    // console.log(chartConfig)

    /**
     * To dynamically set the width of the chart container.
     * if less than 10 then show 2 columns per row else set 1 column per row.
     */
    if (dataLength > 10) {
      setChartWidth(20);
    } else {
      setChartWidth(10);
    }

    /**
     * Set bat configs based on width of chart container
     */
    let newChartData = { ...HorizontalBarConfigs };

    /**
     * Create array of legends from data received
     */

    if (chartConfig.obj) {
      //   newChartData.legend.data = chartConfig.obj.data?.map(
      //     (x) => x.tooltipName
      //   );
      let timeSeries = [];

      chartConfig.obj.data?.map((x) => {
        if (x.values instanceof Array) {
          x.values.forEach(element => {

            let i = timeSeries.findIndex(el => el == element.name);
            if (i == -1) {
              timeSeries.push(element.name);
            }
          });
        }
      })
      if (timeSeries.length > 0) {
        timeSeries = timeSeries.sort()
      }



      let seriesData = chartConfig.obj.data?.map((x) => {
        let mainObj = {
          type: "bar", //chart type
          // stack: x.stack ? x.stack : "",
          name: x.tooltipName, // value that is shown on tooltip when hovered
          label: horizontalLabelOptions,
          barGap: '0%',
          emphasis: {
            focus: 'series'
          },
        };




        if (x.values) {
          /**
           * Set axis of chart based on length of data or chart container
           */
          let arr = [];
          if (x.values instanceof Array) {

            timeSeries.forEach(element => {
              let i = x.values.findIndex(el => el.name == element);
              if (i != -1) {
                arr.push(x.values[i].value);
              } else {
                arr.push(0)
              }
            });
          }


          newChartData[dataLength > 0 ? "yAxis" : "xAxis"] = {
            type: "category",
            data:
              x.values instanceof Array
                ? x.values?.map((y) => {
                  return y.name;
                })
                : [],
            axisLabel: {
              show: dataLength < 20,
              interval: 0, //show all names / donot skip any labels
              rotate: 30, // rotate albels by 30 degree
              formatter: function (name) {
                const twoLines = name.replace(" ", "\n");
                const shortName = name.substring(0, 15); //show only 10 strinh from name
                return dataLength > 10 ? shortName : twoLines;
              },
              textStyle: {
                fontSize: 10,
              },
            },
            name: item.x_axis_name,
            nameLocation: "middle",
            nameGap: dataLength > 0 ? 60 : 30,
          };
          newChartData[dataLength > 0 ? "xAxis" : "yAxis"] = [
            {
              type: "value",
              name: item.y_axis_name,
              nameLocation: "middle",
              nameGap: 60,
              axisLabel: {
                formatter: function (value) {
                  if (value >= 1000000000) {
                    return (value / 1000000000).toFixed(0) + 'B';
                  } else if (value >= 1000000) {
                    return (value / 1000000).toFixed(0) + 'M';
                  } else if (value >= 1000) {
                    return (value / 1000).toFixed(0) + 'K';
                  } else {
                    return value;
                  }
                }
              }
            },
          ];
          mainObj.data =
            x.values && x.values instanceof Array
              ? arr
              : [];
        }
        return mainObj;
      });

      newChartData.series = seriesData; // add data transformed above to series key on main chart object. Main chart object is based on echarts

      return newChartData;
    } else {
      //   newChartData.legend.data = chartConfig.data.nogroup?.map(
      //     (x) => x.tooltipName
      //   );

      let timeSeries = [];

      chartConfig.data.nogroup?.map((x) => {
        if (x.values instanceof Array) {
          x.values.forEach(element => {

            let i = timeSeries.findIndex(el => el == element.name);
            if (i == -1) {
              timeSeries.push(element.name);
            }
          });
        }
      })
      if (timeSeries.length > 0) {
        timeSeries = timeSeries.sort()
      }


      let seriesData = chartConfig.data.nogroup?.map((x) => {
        let mainObj = {
          type: "bar", //chart type
          // stack: x.stack ? x.stack : "",
          name: x.tooltipName, // value that is shown on tooltip when hovered
          label: horizontalLabelOptions,
          barGap: '10%',
          emphasis: {
            focus: 'series'
          },

        };

        if (x.values) {
          /**
           * Set axis of chart based on length of data or chart container
           */

          let arr = [];
          if (x.values instanceof Array) {

            timeSeries.forEach(element => {
              let i = x.values.findIndex(el => el.name == element);
              if (i != -1) {
                arr.push(x.values[i].value);
              } else {
                arr.push(0)
              }
            });
          }


          newChartData[dataLength > 40 ? "yAxis" : "xAxis"] = {
            type: "category",
            data:
              x.values instanceof Array
                ? x.values?.map((y) => {
                  return y.name;
                })
                : [],
            axisLabel: {
              show: dataLength < 20,
              interval: 0, //show all names / donot skip any labels
              rotate: 30, // rotate albels by 30 degree
              formatter: function (name) {
                const twoLines = name.replace(" ", "\n");
                const shortName = name.substring(0, 15); //show only 10 strinh from name
                return dataLength > 10 ? shortName : twoLines;
              },
              textStyle: {
                fontSize: 10,
              },
            },
            name: item.y_axis_name,
            nameLocation: "middle",
            nameGap: dataLength > 40 ? 100 : 50,
          };
          newChartData[dataLength > 40 ? "xAxis" : "yAxis"] = [
            {
              type: "value",
              name: item.x_axis_name,
              nameLocation: "middle",
              nameGap: 30,
            },
          ];
          mainObj.data =
            x.values && x.values instanceof Array
              ? arr
              : [];
        }

        return mainObj;
      });
      console.log("no group data check", seriesData)
      newChartData.series = seriesData; // add data transformed above to series key on main chart object. Main chart object is based on echarts

      return newChartData;
    }
    //rotate label 
  };

  const handleTabularType = (dataLength) => {
    /**
 * To dynamically set the width of the chart container.
 * if less than 10 then show 2 columns per row else set 1 column per row.
 */
    if (dataLength > 10) {
      setChartWidth(20);
    } else {
      setChartWidth(10);
    }

    /**
     * Set bat configs based on width of chart container
     */
    let newChartData = { ...HorizontalBarConfigs };
    // let newChartData =
    //   dataLength > 0 ?{ ...HorizontalBarConfigs }: { ...HorizontalBarConfigs };

    /**
     * Create array of legends from data received
     */
    // console.log( chartConfig , 'chart config objj')
    if (chartConfig.obj) {
      //   newChartData.legend.data = chartConfig.obj.data?.map(
      //     (x) => x.tooltipName
      //   );
      let timeSeries = [];

      chartConfig.obj.data?.map((x) => {
        if (x.values instanceof Array) {
          x.values.forEach(element => {

            let i = timeSeries.findIndex(el => el == element.name);
            if (i == -1) {
              timeSeries.push(element.name);
            }
          });
        }
      })
      if (timeSeries.length > 0) {
        timeSeries = timeSeries.sort()
      }



      let seriesData = chartConfig.obj.data?.map((x) => {
        let mainObj = {
          type: "bar", //chart type
          stack: x.stack ? x.stack : "",
          name: x.tooltipName, // value that is shown on tooltip when hovered
          barGap: '0%',
          emphasis: {
            focus: 'series'
          },
          label: labelOptions
        };


        if (x.values) {
          /**
           * Set axis of chart based on length of data or chart container
           */
          let arr = [];
          if (x.values instanceof Array) {

            timeSeries.forEach(element => {
              let i = x.values.findIndex(el => el.name == element);
              if (i != -1) {
                arr.push(x.values[i].value);
              } else {
                arr.push(0)
              }
            });
          }


          // newChartData[dataLength > 0 ? "yAxis" : "xAxis"] = {
          newChartData["yAxis"] = {
            type: "category",
            data:
              x.values instanceof Array
                ? x.values?.map((y) => {
                  return y.name;
                })
                : [],
            axisLabel: {
              show: dataLength < 20,
              interval: 0, //show all names / donot skip any labels
              rotate: 30, // rotate albels by 30 degree
              formatter: function (name) {
                const twoLines = name.replace(" ", "\n");
                const shortName = name.substring(0, 15); //show only 10 strinh from name
                return dataLength > 10 ? shortName : twoLines;
              },
              textStyle: {
                fontSize: 10,
              },
            },
            name: item.y_axis_name,
            nameLocation: "middle",
            nameGap: dataLength > 0 ? 60 : 30,
          };
          newChartData["xAxis"] = [
            {
              type: "value",
              name: item.x_axis_name,
              nameLocation: "middle",
              nameGap: 60,
            },
          ];
          mainObj.data =
            x.values && x.values instanceof Array
              ? arr
              : [];
        }
        return mainObj;
      });
      newChartData.series = seriesData; // add data transformed above to series key on main chart object. Main chart object is based on echarts

      return newChartData;
    } else {
      //   newChartData.legend.data = chartConfig.data.nogroup?.map(
      //     (x) => x.tooltipName
      //   );

      let timeSeries = [];

      chartConfig.data.nogroup?.map((x) => {
        if (x.values instanceof Array) {
          x.values.forEach(element => {

            let i = timeSeries.findIndex(el => el == element.name);
            if (i == -1) {
              timeSeries.push(element.name);
            }
          });
        }
      })
      if (timeSeries.length > 0) {
        timeSeries = timeSeries.sort()
      }


      let seriesData = chartConfig.data.nogroup?.map((x) => {
        let mainObj = {
          type: "bar", //chart type
          stack: x.stack ? x.stack : "",
          name: x.tooltipName, // value that is shown on tooltip when hovered
          barGap: '10%',
          emphasis: {
            focus: 'series'
          },
          label: labelOptions

        };

        if (x.values) {
          /**
           * Set axis of chart based on length of data or chart container
           */

          let arr = [];
          if (x.values instanceof Array) {

            timeSeries.forEach(element => {
              let i = x.values.findIndex(el => el.name == element);
              if (i != -1) {
                arr.push(x.values[i].value);
              } else {
                arr.push(0)
              }
            });
          }


          newChartData["yAxis"] = {
            type: "category",
            data:
              x.values instanceof Array
                ? x.values?.map((y) => {
                  return y.name;
                })
                : [],
            axisLabel: {
              show: dataLength < 20,
              interval: 0, //show all names / donot skip any labels
              rotate: 30, // rotate albels by 30 degree
              formatter: function (name) {
                const twoLines = name.replace(" ", "\n");
                const shortName = name.substring(0, 15); //show only 10 strinh from name
                return dataLength > 10 ? shortName : twoLines;
              },
              textStyle: {
                fontSize: 10,
              },
            },
            name: item.y_axis_name,
            nameLocation: "middle",
            nameGap: dataLength > 40 ? 100 : 50,
          };
          newChartData["xAxis"] = [
            {
              type: "value",
              name: item.x_axis_name,
              nameLocation: "middle",
              nameGap: 30,
            },
          ];
          mainObj.data =
            x.values && x.values instanceof Array
              ? arr
              : [];
        }

        return mainObj;
      });

      newChartData.series = seriesData; // add data transformed above to series key on main chart object. Main chart object is based on echarts

      return newChartData;
    }

  };

  /***
   * SET DATA BASED ON TYPE
   */

  const setDataBasedOnType = (type) => {
    if (type === "pie") {
      let newChartData = { ...pieChartConfig }; //pie chart setting imported which is created according to echarts examples

      if (chartConfig.obj) {
        newChartData.legend.data = chartConfig.obj.data?.map(
          (x) => x.tooltipName
        );
      } else {
        newChartData.legend.data = chartConfig.data.nogroup?.map(
          (x) => x.tooltipName
        );
      }

      let seriesData = [
        {
          name: chartConfig.chartTitle, // this name is  shown in tooltip during hover of axis.
          type: type,
          radius: '50%',
          label: {
            formatter: '{per|{d}%}  ',
            // backgroundColor: '#F6F8FC',
            // borderColor: '#8C8D8E',
            // borderWidth: 1,
            // borderRadius: 4,
            position: 'inside',
            rich: {
              a: {
                color: '#6E7079',
                lineHeight: 22,
                align: 'center'
              },
              hr: {
                // borderColor: '#8C8D8E',
                width: '100%',
                borderWidth: 1,
                height: 0
              },
              b: {
                // color: '#4C5058',
                fontSize: 14,
                fontWeight: 'bold',
                lineHeight: 33
              },
              per: {
                color: '#fff',
                // backgroundColor: '#4C5058',
                padding: [3, 4],
                borderRadius: 4
              }
            }
          },
          data: chartConfig.obj.data?.map((x) => ({
            name: x.tooltipName,
            value: x.values.map((y) => y.value),
          })),
        },
      ];


      newChartData.series = seriesData;

      setChartData(newChartData);
    } else if (type === "bar") {
      /***
       * Function return chart config based on length of data | chart config is to control vertical and horizontal bar chart.
       */

      if (chartConfig.obj) {
        const newDataSet = handleBarType(
          chartConfig.obj.data[0]?.values?.length
        );
        console.log(newDataSet, "DATA SET")
        setChartData(newDataSet);
      } else {
        const newDataSet = handleBarType(
          chartConfig.data.nogroup[0]?.values?.length
        );
        setChartData(newDataSet);
      }
    } else if (type === "horizontal_stacked_bar") {
      /***
       * Function return chart config based on length of data | chart config is to control vertical and horizontal bar chart.
       */
      if (chartConfig.obj) {
        const newDataSet = handleHBarType(
          chartConfig.obj.data[0]?.values?.length
        );
        setChartData(newDataSet);
      } else {
        const newDataSet = handleHBarType(
          chartConfig.data.nogroup[0]?.values?.length
        );
        setChartData(newDataSet);
      }
    } else if (type === "tabular") {
      if (chartConfig.obj) {
        const newDataSet = handleTabularType(
          chartConfig.obj.data[0]?.values?.length
        );
        setChartData(newDataSet);
      } else {
        const newDataSet = handleTabularType(
          chartConfig.data.nogroup[0]?.values?.length
        );
        setChartData(newDataSet);
      }
    }
    else {
      let newChartData = { ...lineConfigs }; //line chart config imported which is created according to echarts examples

      (newChartData.legend.data = chartConfig?.obj?.data?.map(
        (x) => x.tooltipName
      )); //map values to show legends

      let seriesData = chartConfig?.obj?.data?.map((x) => {
        let mainObj = {
          type: "line", //chart type
          // stack: x.stack ? x.stack : "",
          name: x.tooltipName, // value that is shown on tooltip when hovered
          label: labelOptions,
          emphasis: {
            focus: 'series'
          },
          barGap: '0%'
        };

        if (x.values) {
          newChartData.yAxis = {
            type: "category",
            data:
              x.values instanceof Array
                ? x.values?.map((y) => {
                  return y.name;
                })
                : [],
            axisLabel: {
              show: true,
              interval: 0,
              rotate: 30,
              formatter: function (name) {
                const twoLines = name.replace(" ", "\n");
                const shortName = name.substring(0, 15); //show only 10 strinh from name
                return twoLines;
              },
              textStyle: {
                fontSize: 8,
              },
            }, //show all names / donot skip any labels and rotate labels  by 30 degree.
            name: item.x_axis_name,
            nameLocation: "middle",
            nameGap: 50,
          };
          newChartData.yAxis = [
            {
              type: "value",
              name: item.y_axis_name,
              nameLocation: "middle",
              nameGap: 30,
            },
          ];
          mainObj.data =
            x.values && x.values instanceof Array
              ? x.values?.map((y) => y.value)
              : [];
        }
        return mainObj;
      });
      newChartData.series = seriesData;
      setChartData(newChartData);
    }
  };

  /***
   * CREATE CHART DATA BASED ON CHART TYPE
   */

  useEffect(() => {
    if (Object.values(chartConfig).length > 0) {
      if (chartConfig.charttype === "pieChart" || chartType === "pie") {
        setDataBasedOnType("pie");

      } else if (chartType === "bar" || chartConfig.charttype === "barChart") {
        setDataBasedOnType("bar");
      } else if (chartType === "line" || chartConfig.charttype === "lineChart") {
        setDataBasedOnType("line");
      }
      else if (chartType === "horizontal_stacked_bar" || chartConfig.charttype === "horizontal_stacked_bar") {
        setDataBasedOnType("horizontal_stacked_bar");
      }
      else if (chartType === "tabular" || chartConfig.charttype === "tabularChart") {
        setDataBasedOnType("tabular");
      }
    }
  }, [chartConfig]);

  return (
    <div className={(chartConfig.charttype === "tabular" ? "tabular-view" : 'preview-view')}>
      {chartConfig.charttype === "tabular" ? (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="subtitle1" align="center">
              {chartConfig.chartTitle}
            </Typography>
            {!chartLoader ? (
              <>
                <PreviewTable
                  title={chartConfig.chartTitle}
                  slug={item.Slug}
                  options={chartData}
                  theme={theme}
                />
              </>
            ) : (
              <Loader />
            )}
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <Card
              className="graph__card"
              id={item.Slug}
              style={{ overflow: "unset" }}
            >
              <Typography variant="subtitle1" align="center">
                {chartConfig.chartTitle}
              </Typography>
              {!chartLoader ? (
                <>
                  <PreviewChartCard
                    monthTitle={chartConfig?.headerName}
                    title={chartConfig.chartTitle}
                    slug={item.Slug}
                    options={chartData}
                    theme={theme}
                  />
                </>
              ) : (
                <Loader />
              )}
            </Card>
          </Grid>
        </>
      )}
    </div>
  );
};

export default ChartContainer;

