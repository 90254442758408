import React from "react";
import ReactDOM from "react-dom";
import "./App.css";
import App from "./App";
import './Styles/added.css'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Provider } from "react-redux";
import store from "./store";

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#32651c'
    }
  },
});

function Root() {
  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={store} >
        <App />
      </Provider>
    </MuiThemeProvider>
  );
}



const rootElement = document.getElementById("root");

if (rootElement) {
  ReactDOM.render(<Root />, rootElement);
} else {
  throw new Error("Could not find root element to mount to!");
}
