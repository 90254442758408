import { Typography } from '@material-ui/core'
import React from 'react'

const Table = ({ header, rows, color, title, noCount }) => {


    return (
        <>{
            !title.startsWith("COUNT") &&

            <div className="table__main">
                {
                    title &&
                    <h6 className="table__title">{title}</h6>
                }
                <table className={`table color__${color} text-center table__bordered my-2`}>
                    <thead>
                        <tr>
                            {
                                header.map(x => (
                                    <th key={x.id} scope="col">{x.hName} {noCount ? "" : <> ({ rows.length}) </>}</th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rows.length ?
                                rows.map(x => (
                                    <tr key={x}>
                                        {/* {
                                        x.columns.map(y => ( */}
                                        <th scope="row">{x}</th>
                                        {/* ))
                                    } */}
                                    </tr>
                                ))
                                : <tr>
                                    <th scope="row">No Sites Available</th>
                                </tr>
                        }
                    </tbody>
                </table>
            </div>
        }

        </>
    )
}

export default Table
