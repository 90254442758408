
const initialState = {
    tabWidth: 6,
    minHeight: 510
}

const tabWidthReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case "SET_HEIGHT_WIDTH":
            return {
                ...state,
                tabWidth: payload.width ? payload.width : state.tabWidth,
            }

        default:
            return state;
    }

}

export default tabWidthReducer