export function monthToString( month_number  , type="Np"){
    const month_en = ["January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                        "August",
                        "September",
                        "October",
                        "November",
                        "December"
                    ];    
    const month_np = ["Baishakh",
                        "Jestha",
                        "Ashad",
                        "Shrawan",
                        "Bhadra",
                        "Ashoj",
                        "Kartik",
                        "Mangshir",
                        "Poush",
                        "Magh",
                        "Falgun",
                        "Chaitra"
                    ];

    if ( type == "Np"){
        return month_np[parseInt(month_number) -1 ]
    }else {
        return month_en[parseInt(month_number)-1]
    }
}
