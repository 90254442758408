import React, { useState, useRef, useEffect } from "react";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { RiCloseCircleLine } from "react-icons/ri";
import { MdNavigateNext } from "react-icons/md";
import { MdNavigateBefore } from "react-icons/md";
const useStyles = makeStyles((theme) => ({
  documentContainer: {
    height: "500px"
  },

  pageWrapper :{
    marginTop: "10px",
    marginBottom: "5px"
  },

  page :{
    fontSize: "14px",
    margin: "0 14px"
  }

}));

const MenuClose = styled(RiCloseCircleLine)`
  cursor: pointer;
  font-size: 1.5rem;
  color: red;

  &:hover {
    transform: scale(1.1);
  }
`;

const PDFNext = styled(MdNavigateNext)`
  cursor: pointer;
  font-size: 1.5rem;
  color: green;

  &:hover {
    transform: scale(1.1);
  }
`;

const PDFPrev = styled(MdNavigateBefore)`
  cursor: pointer;
  font-size: 1.5rem;
  color: green;

  &:hover {
    transform: scale(1.1);
  }
`;
const PdfViewer = ({ query }) => {
  const fixedDocumentHeight = 650;
  const classes = useStyles();
  const [pdfUrl, SetPdfUrl] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const [pdfStates, setPdfStates] = useState({numPages: null, pageNumber: 1  });


//   let firstLoad = true;

  useEffect(() => {
      SetPdfUrl({ blob: "./src/assets/pdf/dummy.pdf", name: "test" })
  }, []);

  const onDocumentLoadSuccess =
    (pdfIndex) =>
    ({ numPages }) => {
      setPdfStates({ numPages, pageNumber: 1 })
      setNumPages(numPages);
    };

  const changePage = ( offSet) => {
    const newPdfStates = {...pdfStates};

    newPdfStates.pageNumber += offSet;
    setPdfStates(newPdfStates);

  };

  const changePageBack = () => {
    return () => {
      changePage(-1);
    };
  };

  const changePageNext = () => {
    return () => {
      changePage(1);
    };
  };



  return (
    <>
    <div >
    <center>
                  <header className="App-header">
                    <Document
        className={classes.modal}
                     file={process.env.PUBLIC_URL + '/pdf/factsheet.pdf'}
                      onLoadSuccess={onDocumentLoadSuccess(1)}
                      renderMode="canvas"
                      style={{ height: '500px' }}
                    >
                      <Page
                        pageNumber={pdfStates.pageNumber}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        scale={fixedDocumentHeight / 842}
                      />
                    </Document>
                    <p className={classes.pageWrapper}>
                      <span className={classes.leftArrow}>
                        {pdfStates.pageNumber > 1 && (
                        <PDFPrev
                          onClick={
                            changePageBack()
                          }
                        />
                        )}
                      </span>

                      <span className={classes.page}>Page {pdfStates.pageNumber} of {numPages}</span>

                      <span className={classes.rightArrow}>
                        {pdfStates.pageNumber < numPages && (
                          <PDFNext
                          onClick={
                            changePageNext()
                          }
                          />
                        )}
                      </span>
                    </p>
                    <div >
                      
                      
                    </div>
                  </header>
                </center></div>
    </>
  );
};

export default PdfViewer;
