const { FETCH_TAB_SUCCESS, FETCH_TAB_LOADING } = require("./tabActionTypes");

const initialState = {
    loading: false,
    data: []
}

const tabsReducer = (state = initialState, action) => {
    const { type, payload } = action;
   
    switch (type) {
        case FETCH_TAB_LOADING:
            return {
                ...state,
                loading: true
            }
        case FETCH_TAB_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload
            }
        default:
            return state;
    }

}

export default tabsReducer