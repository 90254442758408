/**
 * PIE CHART SETTINGS
 */

 const gerOptionsxBasedOnAxis = () => {
 const toolboxOptions = {
    show: false,

    left: "right",
    top: "top",
    feature: {
      dataView: {
        show: true,
        readOnly: false,
        title: "Datas",
        optionToContent: function (opt) {
            const datas = opt.series[0].data
            var tdHeads = '<td style="padding:0 5px">Name</td><td style="padding:0 5px">Cases</td> ';
            var table = `
            <table 
                border="1"
                style="margin-left:20px; 
                width: 95%;
                height: 100%;
                border-collapse:collapse;
                font-size:12px;
                text-align:center;
                "
             >
             <tbody>
             <tr style="background-color:#32651C; color: #fff" > ${tdHeads} </tr>`;
            Object.values(datas).forEach(x => {
                table += `
                <tr>
                    <td style="padding: 0 10px">${x.name}</td>
                    <td style="padding: 0 10px">${x.value}</td>
                </tr>`
            })
            table += '</tbody></table>';
            return table
        },
      },
      magicType: {
        show: true,
      },
    },
  };
  return toolboxOptions;
 }
// const pieTableView = {
//     show: false, readOnly: false, title: 'Table',
//     optionToContent: function (opt) {
//         const datas = opt.series[0].data
//         var tdHeads = '<td style="padding:0 5px">Name</td><td style="padding:0 5px">Cases</td> ';
//         var table = `
//         <table 
//             border="1"
//             style="margin-left:20px; 
//             width: 95%;
//             height: 100%;
//             border-collapse:collapse;
//             font-size:12px;
//             text-align:center;
//             "
//          >
//          <tbody>
//          <tr style="background-color:#32651C; color: #fff" > ${tdHeads} </tr>`;
//         Object.values(datas).forEach(x => {
//             table += `
//             <tr>
//                 <td style="padding: 0 10px">${x.name}</td>
//                 <td style="padding: 0 10px">${x.value}</td>
//             </tr>`
//         })
//         table += '</tbody></table>';
//         return table
//     }
// }

export const pieChartConfig = {
    tooltip: {
        chart: "pie", //added just for refrence
        show: true,
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)',
        extraCssText: 'z-index: 999',
        position: function (point, params, dom, rect, size) {
            // where point is the current mouse position, and size has two properties: viewSize and contentSize, which are the size of the outer div and tooltip prompt box, respectively
            var x = point[0];//
            var y = point[1];
            var viewWidth = size.viewSize[0];
            var viewHeight = size.viewSize[1];
            var boxWidth = size.contentSize[0];
            var boxHeight = size.contentSize[1];
            var posX = 0; // x coordinate position
            var posY = 0; // y coordinate position

            if (x < boxWidth) {// Can't let go on the left
                posX = 5;
            } else {// Left down
                posX = x - boxWidth;
            }

            if (y < boxHeight) {// Can't let go above
                posY = 5;
            } else {// The top can be put down
                posY = y - boxHeight;
            }

            return [posX, posY];
        }
    },
    // legend: {
    //     show:false,
    //     orient: 'horizontal',
    //     left: 'left',
    //     width: '87%',
    //     top: '-5px'
    // },
    // toolbox: {
    //     show: true,
    //     feature: {
    //         saveAsImage: { show: true },
    //         //dataView: pieTableView
    //     }
    // },
    // series: [
    //     {
    //         name: 'Cases',
    //         type: 'pie',
    //         // radius: '55%',
    //         center: ['50%', '60%'],
    //         emphasis: {
    //             itemStyle: {
    //                 shadowBlur: 10,
    //                 shadowOffsetX: 0,
    //                 shadowColor: 'rgba(0, 0, 0, 0.5)'
    //             }
    //         }
    //     }
    // ],
    // grid:{
    //     top: "20%",
    // }

    toolbox: gerOptionsxBasedOnAxis(),
    //color: ['#9FE080','#F06D6F'],
        // tooltip: {
        //   trigger: 'item'
        // },
        dataZoom: [
            {
              show: false,
              start: 60,
              end: 100,
              top: '82%'
            },
            {
              type: 'inside',
              start: 60,
              end: 100
            }
          ],
          legend: {
           show: true,
            width: "90%",
            //top: '-5px',
            margin: [0, 0, 10, 0],
            // type: 'scroll',
            bottom: "12%",
          },
         
        series: [
          {
            
            type: 'pie',
            radius: '50%',
           
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      
};

