import * as echarts from "echarts";
import JSPDF from "jspdf";
import React, { useEffect, useRef, useState } from "react";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { RiCloseCircleLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import styled from "styled-components";
import "../../utils/chartTheme";
import "../../utils/chartTheme2";

const MenuDiv = styled.div`
position: absolute;
top: 18%;
right: 80px;
`;

const MenuClose = styled(RiCloseCircleLine)`
  cursor: pointer;
  font-size: 1.5rem;
  position: absolute;
  top: 19%;
  right: 16%;
  color: red;

  &:hover {
    transform: scale(1.1);
  }
`;

const MenuBar = styled(HiOutlineMenuAlt3)`
  cursor: pointer;
  font-size: 1.3rem;
  position: absolute;
  top: 19%;
  right: 19%;
  color: seagreen;

  &:hover {
    transform: scale(1.1);
  }
`;
const MenuDivUl = styled.ul`
  width: 100%;
  list-style: none;
  background: #fff;
  /* border:1px solid lightgray; */
  padding: 0;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
`;
const MenuDivLi = styled.li`
  font-size: 0.8rem;
  width: 100%;
  padding: 5px 30px;
  text-align: center;
  cursor: pointer;
  transition: 0.1s all ease-in-out;
  border: 1px solid lightgray;

  &:hover {
    background: #efefef;
  }
`;

const ChartCard = ({ options, title, slug, handleClose, theme, mainView }) => {
  const myChart = useRef(null);
  const node = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [chartsInstance, setChartsInstance] = useState({});
  const minHeight = useSelector((state) => state.heightWidthData.minHeight);

  /**
   * Initialize echarts
   */

  useEffect(() => {
    if (options) {
      if (mainView) {
        if (options.series[0].data.length * options.series.length > 80) {
          options.dataZoom[0].show = true;
          console.log('options.dataozoom', options);
          //for x axis label

          options.dataZoom[0] = {
            top: "85%",
            start: '90',
            end: '100'
          }


        }
        options.legend.show = true;
        // console.log( 'options.series.length', options.series.length)
        options.series.forEach((element) => {
          if ("label" in element && options.series[0].data.length < 15) {
            element.label.show = true;
          }
        });
        options.legend.align = "auto";
        // if (options.series.length > 10) {
        //   options.grid = {
        //     bottom: "35%",
        //   }; 
        // }  
        // options.legend.type = 'scroll'
        // options.legend.orient = 'horizontal'
        //options.legend.bottom = 20
      } else {
        console.log("options", options)
        // Check if options.dataZoom is defined
        if (options.dataZoom) {
          // Check if options.dataZoom[0] is defined
          if (options.dataZoom[0]) {
            // If defined, set properties
            options.dataZoom[0].show = false;
            options.dataZoom[0].top = "80%";
            options.dataZoom[0].start = "0";
            options.dataZoom[0].end = "100";
          } else {
            // If options.dataZoom[0] is undefined, create it and set properties
            options.dataZoom[0] = {
              show: false,
              top: "80%",
              start: "0",
              end: "100"
            };
          }
        } else {
          // If options.dataZoom is undefined, create it and set properties
          options.dataZoom = [{
            show: false,
            top: "80%",
            start: "0",
            end: "100"
          }];
        }

        options.legend.show = false;

        console.log("options last", options)

        options.series.forEach((element) => {
          if ("label" in element) {
            element.label.show = false;
          }
        });
      }
      const charts = echarts.init(myChart.current, theme, {
        devicePixelRatio: 3,
      });
      charts.setOption(options);
      setChartsInstance(charts);

      // charts.on('dataZoom', function() {
      //   let chartOption = charts.getOption();
      //   console.log(chartOption);
      // });
      charts.on('dataZoom', function (param) {
        const option = charts.getOption()
        // console.log( param , 'param');
        // console.log(option.xAxis[0].data[option.dataZoom[0].startValue],option.xAxis[0].data[option.dataZoom[0].endValue])
        // console.log( param.end  - param.start , 'diff')
        let start, end;
        //batch on mouse scroll/slide and param.start on change in slider
        if (param.batch) {
          start = param.batch[0].start;
          end = param.batch[0].end
        } else {
          start = param.start;
          end = param.end
        }
        if (end - start < 10) {
          option.series.forEach((element) => {
            if ("label" in element) {
              element.label.show = true;
            }
          });
          charts.setOption(option)
        } else {
          option.series.forEach((element) => {
            if ("label" in element) {
              element.label.show = false;
            }
          });
          charts.setOption(option)
        }

      });

    }
  }, [options]);



  /**Close Menu */
  const handleMenuClose = () => {
    setShowMenu(false);
  };

  /**
   * @param  e // event of mouse click
   * to close menu when clicked outside of the menu.
   */
  const handleClick = (e) => {
    if (node.current) {
      if (node.current.contains(e.target)) {
        return;
      }
    }
    handleMenuClose();
  };

  /**
   * Register click event to close menu when clicked outside of menu and remove it when component unmounts.
   */
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick, true);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick, true);
    };
  }, []);




  /***
   * For PDF Download
   */


  const handlePDF = (type) => {
    const pdf = new JSPDF("l", "mm", "a4");
    var width = pdf.internal.pageSize.getWidth();
    var height = pdf.internal.pageSize.getHeight();

    const image = chartsInstance.getDataURL();
    pdf.text(10, 10, title);
    pdf.addImage(image, "JPEG", 0, 50, width, height / 2);
    if (type === "print") {
      pdf.autoPrint();
      pdf.output("dataurlnewwindow");
    } else {
      pdf.save("nationalTB.pdf");
    }
    setShowMenu(false);
  };

  /**
   * @param  extension // type of file to be downloaded.
   */
  const handleDownloadImage = (extension) => {

    const entireDivElement = document.getElementById(slug); //get element by id where elemrnts id is set accouring to title
    const canvasFromDivElement = entireDivElement.querySelectorAll("canvas")[0];
    // console.log( canvasFromDivElement , 'canvasFromDivElement')
    // return;
    // var img = new Image();
    // img.src = chartsInstance.getDataURL({
    //     pixelRatio: 2,
    //     backgroundColor: '#fff'
    // });
    // console.log( 'image', img)
    // return;
    if (chartsInstance) {
      console.log('chartsInstance', chartsInstance)
      var image = chartsInstance
        .getDataURL(`image/${extension}`)
        .replace(`image/${extension}`, "image/octet-stream"); //convert data to uri based on extension
      var link = document.createElement("a"); //create anchor element
      link.download = `nationalTB.${extension}`; //add download attribute to anchor
      link.href = image; //add path to image
      link.click(); // immitate click event to download image
    }
    setShowMenu(false);
  };

  return (
    <>
      <div
        className="main__chart"
        ref={myChart}
        style={{ width: "100%", height: "95%", minHeight: "95%" }}
        id={slug}
      ></div>
      <MenuClose
        onClick={() => {
          handleClose();
        }}
      />
      <MenuBar onClick={() => setShowMenu(!showMenu)} />
      {showMenu && (
        <MenuDiv ref={node}>
          <MenuDivUl>
            <MenuDivLi
              onClick={() => {
                handlePDF("print");
              }}
            >
              Print Chart
            </MenuDivLi>
            <MenuDivLi
              onClick={() => {
                handleDownloadImage("png");
              }}
            >
              Save As PNG
            </MenuDivLi>
            <MenuDivLi
              onClick={() => {
                handleDownloadImage("jpeg");
              }}
            >
              Save As JPG
            </MenuDivLi>
            {/* <MenuDivLi onClick={() => { handleDownloadImage('svg') }}>Save As SVG</MenuDivLi> */}
            <MenuDivLi onClick={() => handlePDF()}>Save As PDF</MenuDivLi>
          </MenuDivUl>
        </MenuDiv>
      )}
    </>
  );
};

export default ChartCard;
