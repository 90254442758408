import { Avatar, withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import React from 'react';
import { BsFillGrid3X3GapFill, BsGraphUp } from 'react-icons/bs';
import { FaCalculator, FaCloudDownloadAlt, FaInfoCircle, FaUserAlt } from 'react-icons/fa';
import { FiFileText } from 'react-icons/fi';
import { GiEarthAsiaOceania, GiTrophyCup } from 'react-icons/gi';
import { GoGraph } from 'react-icons/go';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import tasksicon from '../../assets/images/tasks-icon.png'
import NEP_GOV_Good from '../../assets/images/NEP_GOV_Good.png'

const LinkButtonsWrapper = styled.div`
    /* max-height: 10vh; */
    display: flex;
    background: #dfdfdf;
    align-items: center;
    width: 100%;
    margin: 0;
    @media screen and (max-width:768px){
       flex-direction:column;
        height: auto;
       margin-bottom: 5px;
      
   }
`
const LogoWrapper = styled.div`
   flex: 2;
   display:flex;
   align-items:center;
   padding: 10px 30px;
   font-size: 1.4rem;
   font-weight: bold;

   @media screen and (max-width:960px){
       flex:1;
   }
   @media screen and (max-width:768px){
       width: 100%;
       text-align:center;
       justify-content:center;
       align-items:center;
    padding: 5px 30px;

   }

`
const Logo = styled(Avatar)`
    margin-right: 15px;
    height:50px !important;
    width:50px !important;
`


const ButtonsContainer = styled.div`
   flex: 1;
   display: flex;
   align-items:center;
   justify-content:space-between;
   height: 65px;
   /* margin: 5px auto; */

   @media screen and (max-width:960px){
       flex:1;
   }
   @media screen and (max-width:768px){
       width: 100%;
       height: 50px;
   }
   `
const ButtonsNewTabLinks = styled.a`
    font-size:1.3rem !important;
    font-weight: bold !important;
    cursor: pointer;
    width: 100%;
    text-align:center;
    padding: 3px;
    transition: 0.2s all ease-in-out;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;

    &:hover{
        background: #666;
        color: #fff !important;
    }
 
`
const ButtonsLinks = styled.div`
    font-size:1.3rem !important;
    font-weight: bold !important;
    cursor: pointer;
    width: 100%;
    text-align:center;
    padding: 3px;
    transition: 0.2s all ease-in-out;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover{
        background: #666;
        color: #fff !important;
    }
`

const TaskIcon = styled.img`
    width: 23px;
    height: 23px;
`


const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        fontSize: theme.typography.pxToRem(15),
        zIndex: 9999,
        marginTop: "10%"
    },
}))(Tooltip);

const handleNewTabMapOpen = () => {
    const win = window.open("/http://202.45.146.72:8989/account/login", "_blank");
    win.focus();
}


const LinkButtons = () => {

    const history = useHistory()

    const handleLinkClick = (path) => {
        history.push(path)
    }

    return (
        <LinkButtonsWrapper>
            <LogoWrapper>
                <Logo src={NEP_GOV_Good} />
                National TB Dashboard
            </LogoWrapper>
            <ButtonsContainer>
                <CustomTooltip TransitionComponent={Zoom} title="Dashboard" placement="left-end" arrow>
                    <ButtonsLinks onClick={() => handleLinkClick("/")}>
                        <GoGraph />
                    </ButtonsLinks>
                </CustomTooltip>
                <CustomTooltip TransitionComponent={Zoom} placement="left-end" arrow title="NTCC Work Plan 2021">
                    <ButtonsNewTabLinks onClick={() => handleLinkClick("/tasks")} target="_blank">
                        <TaskIcon tint="#FFFFF" src={tasksicon}/>
                    </ButtonsNewTabLinks>
                </CustomTooltip>
                <CustomTooltip TransitionComponent={Zoom} placement="left-end" arrow title="Map">
                    <ButtonsNewTabLinks href="https://gis.ntpmis.gov.np/" target="_blank">
                        <GiEarthAsiaOceania />
                    </ButtonsNewTabLinks>
                </CustomTooltip>
                <CustomTooltip TransitionComponent={Zoom} placement="left-end" arrow title="Country Profile">
                    <ButtonsNewTabLinks href={process.env.PUBLIC_URL + '/pdf/factsheet.pdf'} target="_blank">
                    <FiFileText />
                    </ButtonsNewTabLinks>
                    
                </CustomTooltip>
                
                {/* <CustomTooltip TransitionComponent={Zoom} placement="left-end" arrow title="Calculator">
                    <ButtonsLinks>
                        <FaCalculator />
                    </ButtonsLinks>
                </CustomTooltip>
                <CustomTooltip TransitionComponent={Zoom} placement="left-end" arrow title="Additional Intervention">
                    <ButtonsLinks>
                        <BsGraphUp />
                    </ButtonsLinks>
                </CustomTooltip>
                <CustomTooltip TransitionComponent={Zoom} placement="left-end" arrow title="Leaderboard">
                    <ButtonsLinks>
                        <GiTrophyCup />
                    </ButtonsLinks>
                </CustomTooltip>
                <CustomTooltip TransitionComponent={Zoom} placement="left-end" arrow title=" Info">
                    <ButtonsLinks>
                        <FaInfoCircle />
                    </ButtonsLinks>
                </CustomTooltip>
                <CustomTooltip TransitionComponent={Zoom} placement="left-end" arrow title="Profile">
                    <ButtonsLinks>
                        <BsFillGrid3X3GapFill />
                    </ButtonsLinks>
                </CustomTooltip> */}
                <CustomTooltip TransitionComponent={Zoom} placement="left-end" arrow title="Admin">
                    <ButtonsNewTabLinks href="http://202.45.146.72:8088/" target="_blank">
                    <FaUserAlt />
                    </ButtonsNewTabLinks>
                </CustomTooltip>

            </ButtonsContainer>
        </LinkButtonsWrapper>
    )
}

export default LinkButtons
