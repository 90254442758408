const { FETCH_CHART_LIST_SUCCESS, FETCH_CHART_LIST_LOADING } = require("./chartListActionTypes");

const initialState = {
    loading: false,
    data: []
}

const chartListReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case FETCH_CHART_LIST_LOADING:
            return {
                ...state,
                loading: true
            }
        case FETCH_CHART_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload
            }
        default:
            return state;
    }

}

export default chartListReducer