import axios from 'axios'

const axiosRequest = axios.create({
    baseURL: process.env.REACT_APP_CHARTCONFIG,
    timeout: 30000,
});

axiosRequest.interceptors.request.use(async (config) => {
    return config
}, function (err) {
    return Promise.reject(err);
});

axiosRequest.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    return Promise.reject(error);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
});

export default axiosRequest



