import { Grid } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import Zoom from "@material-ui/core/Zoom";
import Tabs from "./Tabs";
import PdfViewer from "components/PdfViewer";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import { RiCloseCircleLine } from "react-icons/ri";
import styled from "styled-components";
// import PdfViewer from '../../components/PdfViewer'

const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      backgroundColor: theme.palette.background.paper,
      
    },
    backgroundModal: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: "10px",
      borderRadius: 8,
      position: "relative"
    },
  }));

const MenuClose = styled(RiCloseCircleLine)`
cursor: pointer;
font-size: 1.5rem;
color: red;
position: absolute;
top: 5px;
right: 5px;
z-index: 9999;

&:hover {
  transform: scale(1.1);
}
`;
function HomePage() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const handleOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    return (
        <div className="">
            <div className="page__container" >

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
      >
        <Zoom in={open} style={{ transitionDelay: "100ms" }}>
          <div className={classes.backgroundModal}>
          <MenuClose
        onClick={() => {
          handleClose();
        }}
      />
        <PdfViewer></PdfViewer>
          </div>
        </Zoom>
      </Modal>
                
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Tabs />
                    </Grid>
                </Grid>

            </div>
        </div>
    );
}

export default HomePage;
