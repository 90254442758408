import React from 'react'

const ProfileHeader = () => {
    return (
        <div className="profile__header">
            <h3>Tuberculosis Profile, 2020</h3>
            <h3>Nepal</h3>

        </div>
    )
}

export default ProfileHeader
