import { Grid } from '@material-ui/core'
import React from 'react'
import Images from './Images'
import ProfileHeader from './ProfileHeader'
import ProfileTable from './ProfileTable'
import main from '../../assets/images/main.png'

const Profile = () => {
    return (
        <div className="">
            <Grid container >
                <Grid item xs={12}>
                    <ProfileHeader />
                </Grid>
                <Grid item md={7} xs={12}>
                    <ProfileTable />

                </Grid>
                <Grid item md={5} xs={12}>
                    <Images />
                </Grid>
                <Grid item xs={12}>
                    <img src={main} className="w-100" style={{ height: 405 }} alt="" />

                </Grid>
            </Grid>

        </div>
    )
}

export default Profile
