import CardChartContainer from 'components/CardChartContainer';
import React, { useEffect, useRef, useState } from 'react';

const CardChartRenderer = ({ data, Api, type, searchQuery }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const containerRef = useRef(null);
    const [filteredData, setFilteredData] = useState([]);
    const [scrollInterval, setScrollInterval] = useState(null);

    useEffect(() => {
        const filtered = data.filter(el => el.charttype === "cardChart").sort((a, b) => a.sorting_order - b.sorting_order);
        setFilteredData(filtered);
    }, [data]);

    useEffect(() => {
        if (scrollInterval) {
            clearInterval(scrollInterval);
        }

        const handleAutoScroll = () => {
            const newInterval = setInterval(() => {
                const newIndex = (activeIndex + 3) % filteredData.length;
                setActiveIndex(newIndex);
                scrollNext(newIndex);
            }, 5000);
            setScrollInterval(newInterval);
        };

        handleAutoScroll(); // Start auto-scrolling initially

        return () => {
            if (scrollInterval) {
                clearInterval(scrollInterval);
            }
        };
    }, [activeIndex, filteredData]);

    const handleMouseEnter = () => {
        clearInterval(scrollInterval);
    };

    const handleMouseLeave = () => {
        if (scrollInterval) {
            // Restart auto-scrolling if it was stopped
            const newInterval = setInterval(() => {
                const newIndex = (activeIndex + 3) % filteredData.length;
                setActiveIndex(newIndex);
                scrollNext(newIndex);
            },2000);
            setScrollInterval(newInterval);
        }
    };

    const scrollNext = (newIndex) => {
        if (containerRef.current) {
            const containerWidth = containerRef.current.offsetWidth;
            const cardWidth = containerWidth;
            const scrollAmount = cardWidth * 3;
            containerRef.current.scrollTo({
                left: newIndex * scrollAmount,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div className='preview-wrapper' style={{
            overflowX: 'scroll',
            whiteSpace: 'nowrap',
            transition: 'scroll 2s ease-in-out'
        }}
        ref={containerRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
            {filteredData.map((x, i) => (
                <React.Fragment key={x.Slug}>
                    <CardChartContainer
                        Api={Api}
                        type={type}
                        item={x}
                        searchQuery={searchQuery}
                        theme={i % 2 === 0 ? 'infographic-alt' : 'infographic'}
                    />
                </React.Fragment>
            ))}
        </div>
    );
}

export default CardChartRenderer;
