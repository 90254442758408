import { Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  first_thead : {
    color : '#fff',
    background : '#eaad2a',
    // background : '#32651c',
    // border : '1px solid #a9a9a9'
  },
  second_thead : {
    color : '#fff',
    background : '#7FB9C3',
    // background : '#32651c',
    // border : '1px solid #a9a9a9'
  }
});
const PreviewTable = ( { title, slug , options , theme} ) => {
    const classes = useStyles();
    //paper table is used

    // const [table, setTable] = useState();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [columns, setColumns ]  = useState([]);
    const [rows, setRows ]  = useState([]);
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    
    useEffect(() => {
        
      var axisData = options?.yAxis?.data; //x-axis as a condition, y-axis needs to be changed to yAxis[0].data;
      var series = options?.series;
         // get table heading data
         const col = [];
      col.push( { id: options?.xAxis[0]?.name , label: options?.xAxis[0]?.name , minWidth: 170 })
      col.push({ id: options?.yAxis?.name , label: options?.yAxis?.name , minWidth: 10})

      setColumns( col );
      
          
          
        const row = []
        series.forEach ((ele) =>  {
          return row.push( {  name : ele.name , value : ele.data[0]  } )  
        })
        setRows( row )
        //  // get table row data
        // const tdData =() =>{        
        //     return options.series.map((data)=>{
        //     return(
        //         <tr>
        //             {
        //                 data.data.map((v)=>{
        //                     return <td>{v}</td>
        //                 })
        //             }
        //         </tr>
        //     )
        //     })
        // }
        // setTableBody(tdData)        

        //paper table is used
        // var tdHeads = `<td>${options?.xAxis[0]?.name}</td><td>${options?.yAxis?.name}</td>`;
        // // series.forEach(function (item) {
        // //   tdHeads += "<td>" + item.name + "</td>";
        // // });
        // var table =
        //   `
        //               <table ><thead><tr  className="first__row">` +
        //   tdHeads +
        //   `</tr></thead><tbody>`;
        // var tdBodys = "";
        // series.map((data) => {
        //   tdBodys += `
        //               <tr>
        //                 <td>${data.name}  </td>  
        //                 <td>${data.data[0]}        </td>  
        //               </tr>
        //             `;
        //   // data.data.map( d =>{

        //   // })
        // });
        // table += `${tdBodys}</tbody></table>`;
        // setTable(table);

    }, [options]) 
    return (
        <>
          {/* 
                  //paper table is used
          <div
            className="table-view"
            style={{ width: "100%", height: "96%", minHeight: "96%" }}
            id={slug}
          > 

            <div  dangerouslySetInnerHTML={{__html: table}} >

            </div>

    
          </div> */}
          <Paper >
      <TableContainer >
        <Table stickyHeader aria-label="sticky table">
          <TableHead >
            <TableRow >
              {columns.map((column , i) => (
                <TableCell
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                  className={(i % 2) == 0 ? classes.first_thead : classes.second_thead  }
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {Object.values(row).map((value) => {
                    return (
                      <TableCell>
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
        </>
    );
}


export default PreviewTable;
