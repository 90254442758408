import { AppBar, Grid, Typography, useScrollTrigger } from '@material-ui/core'
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ChartContainer from '../../../components/ChartContainer'
import CardChartContainer from '../../../components/CardChartContainer'
import Loader from '../../../components/Loader'
import { fetchArchiveList, fetchChartList } from '../../../store/chartsList/chartListActions'
import Search from '../Search'
import CardChartRenderer from '../../../components/AutoScroll'



const TabContent = ({ fetchSlug, Api, type, index }) => {

    const dispatch = useDispatch()
    const { data, loading } = useSelector(state => state.chartListData)

    const [highlightedData, setHighlightedData] = useState([])

    const [highlightType, setHighlightType] = useState(0)

    const initialSearchQuery = {
        yearsfrom: "",
        yearsto: "",
        year: "",
        month: "",
        districts: "",
        provinces: "",
        filteryearfrom: "",
        filteryearto: "",
        filtermonthfrom: "",
        filtermonthto: "",
        filteryearengfrom: "",
        filteryearengto: "",
        filtermonthengfrom: "",
        filtermonthengto: "",
        frequency: "4",
        seriesType: "1",
        chartLevel: "toplevel",
        level: "province"

    }
    const [searchQuery, setSearchQuery] = useState(initialSearchQuery)


    const handleSearchInputChange = (request) => {
        //clear searchParams and set searhParams

        if (!request?.target?.name) {
            setSearchQuery(initialSearchQuery)
        } else {
            setSearchQuery({
                ...searchQuery,
                [request.target.name]: request.target.value

            })
        }

    }

    useEffect(() => {
        // setPastFrequency(type?.frequency)
        // if(!pastFrequency?.filter( ( ele) =>{
        //     type?.frequency.includes(ele)
        // }) ){           
        //     handleSearchInputChange()
        // }
        if (type?.frequency) {
            handleSearchInputChange()
        }

    }, [type?.frequency])

    const handleHighlightedChange = (value) => {

        let newHighlightedArray = []
        if (value === 0) {
            newHighlightedArray = data.filter(item => item.highlight === true)
        } else {
            newHighlightedArray = data
        }
        setHighlightedData(newHighlightedArray)
    }


    /***
     * FETCH DATA ON FIRST MOUNT / WHEN SLUG CHANGES || LIST OF CHARTS
     */


    useEffect(() => {
        if (fetchSlug && fetchSlug.length > 0) {
            if (Api === 'chartconfig') {
                dispatch(fetchChartList(fetchSlug + '-' + searchQuery.chartLevel))
            }
            else {
                dispatch(fetchArchiveList(fetchSlug + '-' + searchQuery.chartLevel))
            }


            setHighlightType(0)
        }
    }, [searchQuery.chartLevel, fetchSlug])

    useEffect(() => {
        if (data) {
            handleHighlightedChange(highlightType)
        }
    }, [data, highlightType])


    // const [activeIndex, setActiveIndex] = useState(0);
    // const containerRef = useRef(null);
    // const cardWidth = 400; // Width of each card
    // const [filteredData, setFilteredData] = useState([]);

    // useEffect(() => {
    //     const filtered = data.filter(el => el.charttype === "cardChart").sort((a, b) => a.sorting_order - b.sorting_order);
    //     setFilteredData(filtered);
    // }, [data]);


    // useEffect(() => {
    //     const scrollInterval = setInterval(() => {
    //         const newIndex = (activeIndex + 1) % filteredData.length;
    //         setActiveIndex(newIndex);
    //         scrollNext();
    //     }, 2000); // Change the interval as needed

    //     return () => clearInterval(scrollInterval);
    // }, [activeIndex]);

    // const scrollNext = () => {
    //     if (containerRef.current) {
    //         if (activeIndex === filteredData.length - 1) {
    //             containerRef.current.scrollLeft = 0;
    //             setActiveIndex(0);
    //         } else {
    //             containerRef.current.scrollLeft = (activeIndex + 1) * cardWidth;
    //             setActiveIndex(activeIndex + 1);
    //         }
    //     }
    // };

    return (
        <>
            <Grid container spacing={3} style={{ marginTop: 1 }}>
                <Grid item xs={12} sm={12} md={12} >
                    <Search
                        Api={Api}
                        type={type}
                        searchQuery={searchQuery}
                        handleSearchInputChange={handleSearchInputChange}
                        highlightType={highlightType}
                        handleHighlightedChange={handleHighlightedChange}
                        setHighlightType={setHighlightType}
                        index={index}
                    />
                </Grid>
            </Grid>
            {loading ? <Loader /> :
                <>

                    {
                        data.length > 0 && !loading ?
                            <div>
                                {/* <div className='preview-wrapper' ref={containerRef} style={{ overflowX: 'scroll', whiteSpace: 'nowrap' }}>
                                    {
                                        data.filter(el => el.charttype === "cardChart").sort((a, b) => a.sorting_order - b.sorting_order).map((x, i) => (
                                            <React.Fragment key={x.Slug}>
                                                <CardChartContainer
                                                    Api={Api}
                                                    type={type}
                                                    item={x}
                                                    index={index}
                                                    searchQuery={searchQuery}
                                                    theme={i % 2 === 0 ? 'infographic-alt' : 'infographic'}
                                                />
                                            </React.Fragment>
                                        ))
                                    }
                                </div> */}
                                <CardChartRenderer
                                    data={data}
                                    Api={Api}
                                    type={type}
                                    searchQuery={searchQuery}
                                />


                                <Grid container spacing={2} className="chart__content__style">
                                    {
                                        data.filter(el => el.charttype != "cardChart").map((x, i) => (
                                            <React.Fragment key={x.Slug}>
                                                <ChartContainer
                                                    Api={Api}
                                                    type={type}
                                                    item={x}
                                                    index={index}
                                                    searchQuery={searchQuery}
                                                    theme={i % 2 === 0 ? 'infographic-alt' : 'infographic'}
                                                />
                                            </React.Fragment>
                                        ))
                                    }
                                </Grid>
                            </div>
                            :
                            <Grid item xs={12} style={{ marginTop: 50 }}>
                                <Typography align="center" variant="h5">No Data Found</Typography>
                            </Grid>
                    }
                </>
            }

        </>
    )
}

export default TabContent
