import axios from 'axios'
const axiosRequests = axios.create({
    baseURL: process.env.REACT_APP_ARCHIVE,
    timeout: 30000,
});

axiosRequests.interceptors.request.use(async (config) => {
    return config
}, function (err) {
    return Promise.reject(err);
});

axiosRequests.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    return Promise.reject(error);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
});

export default axiosRequests