import { Grid } from '@material-ui/core'
import React from 'react'


const Tasks = () => {
    return (
        <div className="">
            <Grid container >
                <Grid item xs={12}>
                    <iframe
                        className="clickup-embed"
                        src="https://sharing.clickup.com/g/h/38dr3-25/34319a79208882b"
                        onWheel=""
                        width="100%"
                        height="700px"
                        style={{background: "transparent",  border: "1px solid #ccc"}}
                    ></iframe>
                </Grid>
            </Grid>

        </div>
    )
}

export default Tasks
