import tabsReducer from "./tabs/tabReducer";
import chartListReducer from "./chartsList/chartListReducer";
import chartWidthReducer from "./chartWidth/chartWidthReducer";
import searchsReducer from "./search/searchReducer";
const { combineReducers } = require("redux");

const rootReducer = combineReducers({
    tabData: tabsReducer,
    chartListData: chartListReducer,
    heightWidthData: chartWidthReducer,
    searchData : searchsReducer,
})

export default rootReducer