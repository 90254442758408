// @flow
import Footer from 'components/Footer';
import Tasks from 'pages/Tasks';
import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import NewNavBar from './components/NewNavBar';
import HomePage from './pages/Dashboard';
import Profile from './pages/Profile';



const App = (props) => {

  return (
    <>
    
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/tasks" component={Tasks} />

        </Switch>
        <Footer />
      </BrowserRouter>
      {/* {props.children} */}
    </>
  )
}

export default App
