import AppBar from "@material-ui/core/AppBar";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import HealingIcon from "@material-ui/icons/Healing";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import Loader from "../../../components/Loader";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FaHospitalSymbol } from "react-icons/fa";
import { GiMedicalPackAlt, GiMedicines } from "react-icons/gi";
import { RiCapsuleFill, RiLungsFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { fetTabbarData } from "../../../store/tabs/tabAction";
import TabContent from "../TabContent";
import Gxmis from "./gxmis";
import NewNavBar from '../../../components/NewNavBar';
import TabCardContent from "../../../components/AutoScroll";

const StyledTab = withStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    fontSize: "0.8rem",
  },
})(Tab);

const StyledTabs = withStyles({
  root: {
    flexDirection: "row",
    fontSize: "0.8rem",
    minHeight: "33px",
  },
})(Tabs);
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  wrapper: {
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
  etbTab: {
    background: "#7FB9C3",
    color: "#fff",
    fontWeight: "bold",
    minHeight: "15px",
    padding: "5px 0px",
    minWidth: 120,
  },
  genTab: {
    background: "#eaad2a",
    color: "#fff",
    fontWeight: "bold",
    minHeight: "15px",
    padding: "5px 0px",
    minWidth: 120,
  },
  drtbTab: {
    background: "#f46161",
    color: "#fff",
    fontWeight: "bold",
    minHeight: "15px",
    padding: "5px 0px",
    minWidth: 120,
  },
  cdstTab: {
    background: "#91CC75",
    color: "#fff",
    fontWeight: "bold",
    minHeight: "15px",
    padding: "5px 0px",
    minWidth: 120,
  },
  etbCasesTab: {
    background: "#a3a41e",
    color: "#fff",
    fontWeight: "bold",
    minHeight: "15px",
    padding: "5px 0px",
    minWidth: 120,
  },
  ktbTab: {
    background: "#e088ea",
    color: "#fff",
    fontWeight: "bold",
    minHeight: "15px",
    padding: "5px 0px",
    minWidth: 120,
  },
  gxmisTab: {
    background: "#f4995d",
    color: "#fff",
    fontWeight: "bold",
    minHeight: "15px",
    padding: "5px 0px",
    minWidth: 120,
  },
  tabContentStyle: {
    padding: "15px 0",
    minHeight: "15px",
  },
  muiIconStyle: {
    marginRight: 5,
    fontSize: "1.2rem",
    minHeight: "15px",
  },
}));

function TabPanel(props) {
  const classes = useStyles();

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className={classes.tabContentStyle}>{children}</div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

export default function MainTabs() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [subvalue, setSubValue] = useState(0);
  const [availableConfig, setAvailableConfig] = useState([]);
  const [fetchSlug, setFetchSlug] = useState("");
  const [availableSubConfig, setAvailableSubConfig] = useState([]);
  const [fetchSubSlug, setFetchSubSlug] = useState("");

  const tabsArrayData = useSelector((state) => state.tabData.data);
  const tabsDataLoading = useSelector((state) => state.tabData.loading);

  const handleChange = (event, newValue) => {
    setSubValue(0);
    setValue(newValue);
    setFetchSlug(availableConfig[newValue]);
  };

  const handleSubChange = (event, newsubValue) => {
    setSubValue(newsubValue);
    setFetchSubSlug(availableSubConfig[newsubValue]);
  };

  /**
   * Fetch all available configs || tab names
   */

  React.useEffect(() => {
    dispatch(fetTabbarData());
  }, []);

  React.useEffect(() => {
    if (tabsArrayData && tabsArrayData instanceof Array) {
      const newHeader = tabsArrayData.map((x) => {
        return x.Slug;
      });

      setAvailableConfig(newHeader);
      setFetchSlug(newHeader[0]);
    }
  }, [tabsArrayData]);

  React.useEffect(() => {
    if (subtab && subtab instanceof Array) {
      const newSubHeader = subtab.map((x) => {
        return x.subtab_Slug;
      });

      setAvailableSubConfig(newSubHeader);
      setFetchSubSlug(newSubHeader[0]);
    }
  }, [fetchSlug]);

  const IconsArray = [
    <RiLungsFill className={classes.muiIconStyle} />,
    <RiCapsuleFill className={classes.muiIconStyle} />,
    <HealingIcon className={classes.muiIconStyle} />,
    <LocalHospitalIcon className={classes.muiIconStyle} />,
    <GiMedicines className={classes.muiIconStyle} />,
    <FaHospitalSymbol className={classes.muiIconStyle} />,
    <GiMedicalPackAlt className={classes.muiIconStyle} />,
  ];
  const TabsClass = [
    "etbTab",
    "genTab",
    "drtbTab",
    "cdstTab",
    "etbCasesTab",
    "ktbTab",
    "gxmisTab",
  ];

  let subtab = [];

  return (
    <>
      {tabsDataLoading ? (
        <Loader />
      ) : (
        <div className={`${classes.root} tabs__design`}>
          <div className="view_header">
            <NewNavBar />
            <AppBar position="static" color="default" className="nav__menu-item">
              <StyledTabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                textColor="primary"
                centered
                className={`tabs__flex ${classes.wrapper} header-tab`}
                TabIndicatorProps={{
                  style: { background: "#6153A5", height: 3, color: "#576E81" },
                }}
              >
                {tabsArrayData &&
                  tabsArrayData instanceof Array &&
                  tabsArrayData.map((item, index) => (
                    <StyledTab
                      key={index}
                      label={
                        <>
                          {" "}
                          {IconsArray[index]}{" "}
                          <span className="title-section">{item.Title}</span>
                        </>
                      }
                      {...a11yProps(index)}
                      className={classes[TabsClass[index]]}
                    />
                  ))}
              </StyledTabs>

              {tabsArrayData.length > 0 ? (
                <>
                  {tabsArrayData[value].subtab.map((item) => {
                    subtab.push(item);
                  })}

                  <div className={`submenu-tab`}>
                    <StyledTabs
                      value={subvalue}
                      onChange={handleSubChange}
                      variant="fullWidth"
                      textColor="primary"
                      centered
                      className={`tabs__flex ${classes.wrapper} tab${value}`}
                      TabIndicatorProps={{
                        style: {
                          background: "#6153A5",
                          height: 3,
                          color: "#576E81",
                        },
                      }}
                    >
                      {subtab &&
                        subtab.map((item, index) => (
                          <StyledTab
                            key={index}
                            label={
                              <>
                                {" "}
                                {IconsArray[index]}{" "}
                                <span className="pd-r">{item.subtab_name}</span>
                              </>
                            }
                            {...a11yProps(index)}
                            className={classes[TabsClass[index]]}
                          />
                        ))}
                    </StyledTabs>
                  </div>
                </>
              ) : (
                <></>
              )}
            </AppBar>
          </div>


          <div className="page__content">
            {(fetchSubSlug && fetchSlug === "overview") ||
              fetchSlug === "ntpmis" ||
              fetchSlug === "hmis" || fetchSlug === "pudr-indicators" ? (
              <>
                <TabContent
                  fetchSlug={fetchSubSlug}
                  Api={"chartconfig"}
                  type={subtab[subvalue]}
                  index={subvalue}
                  mindex={value}
                />
              </>
            ) : (
              <>
                <TabContent
                  fetchSlug={fetchSubSlug}
                  Api={"archived_chart"}
                  type={subtab[subvalue]}
                  index={subvalue}
                  mindex={value}
                />
              </>
            )}
            {availableConfig && availableConfig.length > 0 && (
              <TabPanel value={value} index={availableConfig.length}></TabPanel>
            )}
          </div>
        </div>
      )}
    </>
  );
}
