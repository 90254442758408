import React from 'react'

const ProfileTable = () => {
    return (
        <div className="table__page">

            <table className="table table-striped ">
                <thead className="table-custom-green">
                    <tr>
                        <th scope="col">Estimates of TB burden, 2075/76</th>
                        <th scope="col" className="text-center">Number </th>
                        <th scope="col" className="text-center">Rate (per 100000 Population)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="table-primary">
                        <td scope="row">Total TB incidence</td>
                        <td className="text-center">68000</td>
                        <td className="text-center"> 238 (141-359)</td>
                    </tr>
                    <tr className="table-primary">
                        <td scope="row">Total TB incidence</td>
                        <td className="text-center">490</td>
                        <td className="text-center"> 1.7(1-2.6)</td>
                    </tr>
                    <tr className="table-primary">
                        <td scope="row">MDR/RR-TB incidence</td>
                        <td className="text-center">2200</td>
                        <td className="text-center"> 7.6 (3.1–14)</td>
                    </tr>
                    <tr className="table-primary">
                        <td scope="row">HIV negative TB motality</td>
                        <td className="text-center">17000</td>
                        <td className="text-center"> 58 (32–92)</td>
                    </tr>
                    <tr className="table-primary">
                        <td scope="row">HIV positive TB mortality</td>
                        <td className="text-center">220</td>
                        <td className="text-center"> 0.77 (0.45–1.2)</td>
                    </tr>

                </tbody>
            </table>
            <table className="table table-striped ">
                <thead className="table-custom-green">
                    <tr>
                        <th scope="col">Estimated proportion of TB cases with MDR/RR-TB, 2076/77</th>
                        <th scope="col" className="text-center"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="table-primary">
                        <td scope="row">New cases</td>
                        <td className="text-center">2.2% (1.1–3.6)</td>
                    </tr>
                    <tr className="table-primary">
                        <td scope="row">Previously treated cases</td>
                        <td className="text-center">15% (9.6–22)</td>
                    </tr>

                </tbody>
            </table>
            <table className="table table-striped ">
                <thead className="table-custom-green">
                    <tr>
                        <th scope="col">TB case notifications, 2076/77</th>
                        <th scope="col" className="text-center"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="table-primary">
                        <td scope="row">Total new and relapse</td>
                        <td className="text-center">27232</td>
                    </tr>

                    <tr className="table-primary">
                        <td scope="row">– % tested with rapid diagnostics at time of diagnosis</td>
                        <td className="text-center"></td>
                    </tr>

                    <tr className="table-primary">
                        <td scope="row">–'% with known HIV status</td>
                        <td className="text-center">51%</td>
                    </tr>

                    <tr className="table-primary">
                        <td scope="row">– % pulmonary</td>
                        <td className="text-center">68%</td>
                    </tr>

                    <tr className="table-primary">
                        <td scope="row">–% bacteriologically confirmed</td>
                        <td className="text-center">54%</td>
                    </tr>

                    <tr className="table-primary">
                        <td scope="row">–% children aged 0-14 years </td>
                        <td className="text-center">6.0%</td>
                    </tr>

                    <tr className="table-primary">
                        <td scope="row">–% women </td>
                        <td className="text-center">37%</td>
                    </tr>

                    <tr className="table-primary">
                        <td scope="row">–% men </td>
                        <td className="text-center">63%</td>
                    </tr>

                    <tr className="table-primary">
                        <td scope="row">Total cases notified</td>
                        <td className="text-center">27745</td>
                    </tr>


                </tbody>
            </table>
            <table className="table table-striped ">
                <thead className="table-custom-green">
                    <tr>
                        <th scope="col">Universal health coverage and social protection</th>
                        <th scope="col" className="text-center"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="table-primary">
                        <td scope="row">TB treatment coverage (notified/estimated incidence), 2075/76</td>
                        <td className="text-center">46% (31-78)</td>
                    </tr>
                    <tr className="table-primary">
                        <td scope="row">TB patients facing catastrophic total costs</td>
                        <td className="text-center"></td>
                    </tr>
                    <tr className="table-primary">
                        <td scope="row">TB Case fatality ratio (estimated mortality/estimated incidence), 2075/76</td>
                        <td className="text-center">26% (11-45) </td>
                    </tr>


                </tbody>
            </table>
            <table className="table table-striped ">
                <thead className="table-custom-green">
                    <tr>
                        <th scope="col">TB/HIV care in new and relapse TB patients, 2076/77</th>
                        <th scope="col" className="text-center">Number</th>
                        <th scope="col" className="text-center">(%)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="table-primary">
                        <td scope="row">Patients with known HIV-status who are HIV-positive</td>
                        <td className="text-center">123</td>
                        <td className="text-center">0.9%</td>
                    </tr>

                    <tr className="table-primary">
                        <td scope="row">on antiretroviral therapy</td>
                        <td className="text-center">113</td>
                        <td className="text-center">92%</td>
                    </tr>

                </tbody>
            </table>
            <table className="table table-striped ">
                <thead className="table-custom-green">
                    <tr>
                        <th scope="col">Drug-resistant TB care, 2076/77</th>
                        <th scope="col" className="text-center"></th>

                    </tr>
                </thead>
                <tbody>
                    <tr className="table-primary">
                        <td scope="row">% of bacteriologically confirmed TB cases tested for rifampicin resistance</td>
                        <td className="text-center"></td>
                        
                    </tr>
                    <tr className="table-primary">
                        <td scope="row">New cases </td>
                        <td className="text-center">
                        59%
                           
                        </td>
                        
                    </tr>
                    <tr className="table-primary">
                        <td scope="row">Previously treated cases</td>
                        <td className="text-center">
                        67%
                            
                           
                        </td>
                        
                    </tr>
                    <tr className="table-primary">
                        <td scope="row">Laboratory-confirmed cases</td>
                        <td className="text-center">
                        MDR/RR-TB:376, XDR-TB: 8
                        </td>
                        
                    </tr>
                    <tr className="table-primary">
                        <td scope="row">Patients started on treatment</td>
                        <td className="text-center">
                           
                            MDR/RR-TB:376, XDR-TB: 8
                        </td>
                        
                    </tr>
                    <tr className="table-primary">
                        <td scope="row">MDR/RR-TB cases tested for resistance to second-line drugs</td>
                        <td className="text-center">
                           
                            315
                        </td>
                        
                    </tr>

                </tbody>
            </table>
            <table className="table table-striped ">
                <thead className="table-custom-green">
                    <tr>
                        <th scope="col">Treatment success rate and cohort size</th>
                        <th scope="col" className="text-center">Success</th>
                        <th scope="col" className="text-center">Cohort</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="table-primary">
                        <td scope="row">New and relapse cases registered in 2075/76</td>
                        <td className="text-center">89%</td>
                        <td className="text-center">30,464 </td>
                    </tr>
                    <tr className="table-primary">
                        <td scope="row">Previously treated cases, excluding relapse, registered in 2075/76</td>
                        <td className="text-center">88%</td>
                        <td className="text-center">1,128 </td>
                    </tr>
                    <tr className="table-primary">
                        <td scope="row">MDR/RR-TB cases started on second-line treatment in 2074/75</td>
                        <td className="text-center">75%</td>
                        <td className="text-center">328</td>
                    </tr>
                    <tr className="table-primary">
                        <td scope="row">XDR-TB cases started on second-line treatment in 2074/75</td>
                        <td className="text-center">67%</td>
                        <td className="text-center">6</td>
                    </tr>

                </tbody>
            </table>
            <table className="table table-striped ">
                <thead className="table-custom-green">
                    <tr>
                        <th scope="col">TB preventive treatment, 2076/77</th>
                        <th scope="col" className="text-center"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="table-primary">
                        <td scope="row">% of HIV-positive people (newly enrolled in care) on preventive treatment</td>
                        <td className="text-center">__</td>
                    </tr>
                    <tr className="table-primary">
                        <td scope="row">% of children (aged {"<"}5) household contracts of bacteriologically-confirmed TB cases on preventive treatment</td>
                        <td className="text-center">89%</td>
                    </tr>
                   


                </tbody>
            </table>
            <table className="table table-striped ">
                <thead className="table-custom-green">
                    <tr>
                        <th scope="col">TB financing, 2076/77</th>
                        <th scope="col" className="text-center">Funding Source</th>
                        <th scope="col" className="text-center">(US$ Million )</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="table-primary">
                        <td scope="row" rowSpan={3}>National TB budget </td>
                        <td className="text-center">Nepal GoV: </td>
                        <td className="text-center">7.25 </td>
                    </tr>
                    <tr className="table-primary">
                        <td className="text-center">Global Fund: </td>
                        <td className="text-center">6.67 </td>
                    </tr>
                    <tr className="table-primary">
                            <td className="text-center">WHO and other Partners: </td>
                        <td className="text-center">0.22 </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ProfileTable
