const gerOptionsxBasedOnAxis = (type) => {
  const toolboxOptions = {
    show: false,

    left: "right",
    top: "top",
    feature: {
      dataView: {
        show: true,
        readOnly: false,
        title: "Data",
        optionToContent: function (opt) {  
          console.log( 'type' , type)
          console.log( 'opt' , opt)
          var axisData = opt[type][0].data; //x-axis as a condition, y-axis needs to be changed to yAxis[0].data;
          var series = opt.series;
          var tdHeads = "<td>Name</td>";
          series.forEach(function (item) {
            tdHeads += "<td>" + item.name + "</td>";
          });
          var table =
            `
                        <table ><thead><tr  className="first__row">` +
            tdHeads +
            `</tr></thead><tbody>`;
          var tdBodys = "";
          for (var i = 0, l = axisData?.length; i < l; i++) {
            for (var j = 0; j < series.length; j++) {
              if (typeof series[j].data[i] == "object") {
                if (series[j].data[i].value) {
                  tdBodys += "<td>" + series[j].data[i].value + "</td>";
                } else {
                  tdBodys += "<td>" + "-" + "</td>";
                }
              } else {
                if (series[j].data[i]) {
                  tdBodys += "<td>" + series[j].data[i] + "</td>";
                } else {
                  tdBodys += "<td>" + "-" + "</td>";
                }
              }
            }
            table += "<tr><td>" + axisData[i] + "</td>" + tdBodys + "</tr>";
            tdBodys = "";
          }
          table += "</tbody></table>";
          return table;
        },
      },
      magicType: {
        show: true,
        type: ["line", "bar"],
        title: {
          line: "Line Chart",
          bar: "Bar Chart",
        },

      },
    },
  };
  return toolboxOptions;
};

export const lineConfigs = {
  // color: ['#9FE080', '#5C7BD9', '#F06D6F', '#3300DB', '#de3423'],
  toolbox: gerOptionsxBasedOnAxis("xAxis"),
  tooltip: {
    show: true,
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
  },
  xAxis: [
    {
      type: "category",
      // confine: true,
      axisLabel: {
        interval: 0,
        rotate: 90, //If the label names are too long you can manage this by rotating the label.
        formatter: function (name) {
          const shortName = name.substring(0, 12);
          return shortName;
        },
        // confine: true,
        position: function (point, params, dom, rect, size) {
          // where point is the current mouse position, and size has two properties: viewSize and contentSize, which are the size of the outer div and tooltip prompt box, respectively
          var x = point[0]; //
          var y = point[1];
          var viewWidth = size.viewSize[0];
          var viewHeight = size.viewSize[1];
          var boxWidth = size.contentSize[0];
          var boxHeight = size.contentSize[1];
          var posX = 0; // x coordinate position
          var posY = 0; // y coordinate position

          if (x < boxWidth) {
            // Can't let go on the left
            posX = 5;
          } else {
            // Left down
            posX = x - boxWidth;
          }

          if (y < boxHeight) {
            // Can't let go above
            posY = 5;
          } else {
            // The top can be put down
            posY = y - boxHeight;
          }

          return [posX, posY];
        },
      },
    },
  ],
  yAxis: [
    {
      type: "value",
      name: "Value",
      nameLocation: "middle",
      nameGap: 30,
    },
  ],

  legend: {
    // show:true,
    width: "90%",
    //top: '-5px',
    margin: [0, 0, 10, 0],
    type: 'scroll',
    bottom: '5px'
  },
  grid: {
    top: "20%",
  },
};

export const VerticalBarConfigs = {
  // color: ['#9FE080', '#5C7BD9', '#F06D6F', '#3300DB', '#de3423'],
  toolbox: gerOptionsxBasedOnAxis("xAxis"),
  tooltip: {
    show: true,

    //  trigger: 'axis',
    axisPointer: {
      type: "shadow",
    },
    // position: function (point, params, dom, rect, size) {

    //     // where point is the current mouse position, and size has two properties: viewSize and contentSize, which are the size of the outer div and tooltip prompt box, respectively
    //     var x = point[0];//
    //     var y = point[1];
    //     var viewWidth = size.viewSize[0];
    //     var viewHeight = size.viewSize[1];
    //     var boxWidth = size.contentSize[0];
    //     var boxHeight = size.contentSize[1];
    //     var posX = 0; // x coordinate position
    //     var posY = 0; // y coordinate position

    //     if (x < boxWidth) {// Can't let go on the left
    //         posX = 5;
    //     } else {// Left down
    //         posX = x - boxWidth;
    //     }

    //     if (y < boxHeight) {// Can't let go above
    //         posY = 5;
    //     } else {// The top can be put down
    //         posY = y - boxHeight;
    //     }

    //     return [posX, posY];
    // }
  },
  yAxis: [
    {
      type: "category",
      axisLabel: {
        interval: 0,
        rotate: 30, //If the label names are too long you can manage this by rotating the label.
        formatter: function (value) {
          console.log('VAL',value)
          if (value >= 1000000000) {
              return (value / 1000000000).toFixed(1) + 'B';
          } else if (value >= 1000000) {
              return (value / 1000000).toFixed(1) + 'M';
          } else if (value >= 1000) {
              return (value / 1000).toFixed(1) + 'K';
          } else {
              return value;
          }
        }
      },
    
    },
  ],
  xAxis: [
    {
      type: "value",
      name: "Value",
      nameLocation: "right",
      nameGap: 30,
    },
  ],
  legend: {
    // show:false,
    width: "90%",
    //top: '-5px',
    margin: [0, 0, 10, 0],
    type: 'scroll',
    bottom: "5px",
  },
  grid: {
    bottom: "20%",
  },
  dataZoom: [
    {
      show: false,
      // type: 'slider',
      // xAxisIndex: 0,
      // filterMode: 'weakFilter',
      // height: 20,
      // bottom: 80,
      start: 0,
      end: 100,
      // handleIcon:
      //   'path://M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
      // handleSize: '80%',
      // showDetail: false,
     // top: '80'
    },
    {
      type: 'inside',
      start: 0,
      end: 100
    },
    // {
    //   show: true,
    //   yAxisIndex: 0,
    //   filterMode: 'empty',
    //   width: 30,
    //   height: '80%',
    //   showDataShadow: false,
    //   left: '93%'
    // }
  ],
};

export const HorizontalBarConfigs = {
  // color: ['#9FE080', '#5C7BD9', '#F06D6F', '#3300DB', '#de3423'],
  toolbox: gerOptionsxBasedOnAxis("yAxis"),
  tooltip: {
    show: true,
    // confine: true,
    // trigger: 'axis',
    axisPointer: {
      type: "shadow",
      // confine: true,
    },
    // position: function (point, params, dom, rect, size) {
    //     // where point is the current mouse position, and size has two properties: viewSize and contentSize, which are the size of the outer div and tooltip prompt box, respectively
    //     var x = point[0];//
    //     var y = point[1];
    //     var viewWidth = size.viewSize[0];
    //     var viewHeight = size.viewSize[1];
    //     var boxWidth = size.contentSize[0];
    //     var boxHeight = size.contentSize[1];
    //     var posX = 0; // x coordinate position
    //     var posY = 0; // y coordinate position

    //     if (x < boxWidth) {// Can't let go on the left
    //         posX = 5;
    //     } else {// Left down
    //         posX = x - boxWidth;
    //     }

    //     if (y < boxHeight) {// Can't let go above
    //         posY = 5;
    //     } else {// The top can be put down
    //         posY = y - boxHeight;
    //     }

    //     return [posX, posY];
    // }
  },
  legend: {
    // show:false,
    width: "90%",
    //top: '-5px',
    // margin: [0, 0, 10, 0],
    type: 'scroll',
    bottom: "10",
    left: '20',
    orient:'horizontal',
    height:'100',
  },
  xAxis: [
    {
      type: "category",
      axisLabel: {
        interval: 0,
        rotate: 30, //If the label names are too long you can manage this by rotating the label.
      },
    },
  ],
  yAxis: [
    {
      type: "value",
      name: "Value",
      nameLocation: "middle",
      nameGap: 30,
    },
  ],

    grid:{
        bottom: "20%",
    },
    dataZoom: [
      {
        show: false,
        // type: 'slider',
        // xAxisIndex: 0,
        // filterMode: 'weakFilter',
        // height: 20,
        // bottom: 80,
        start: 0,
        end: 100,
        // handleIcon:
        //   'path://M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
        // handleSize: '80%',
        // showDetail: false
        
      },
      {
        type: 'inside',
        start: 0,
        end: 100,
   
      },
      // {
      //   show: true,
      //   yAxisIndex: 0,
      //   filterMode: 'empty',
      //   width: 30,
      //   height: '80%',
      //   showDataShadow: true,
      //   left: '93%'
      // }
    ],

}

const config = {
    rotate: 90,
    align: "left",
    verticalAlign: "middle",
    position: "insideBottom",
    distance: 15,
    onChange: function () {
      const labelOption = {
        rotate: config.rotate,
        align: config.align,
        verticalAlign:config.verticalAlign,
        // position: app.config.position,
        distance: config.distance,
      };
    },
  };
  
  export const labelOptions = {
    show: true,
    position: config.position,
    distance: config.distance,
    align: config.align,
    verticalAlign: config.verticalAlign,
    rotate: config.rotate,
    // formatter: "{c}  {name|{a}}",
    formatter: "{c}",
    fontSize: 14,
    rich: {
      name: {},
    },
  };
  
  export const horizontalLabelOptions = {
    show: true,
    position: config.position,
    distance: config.distance,
    align: config.align,
    verticalAlign: config.verticalAlign,
    rotate:0,
    // formatter: "{c}  {name|{a}}",
    formatter: "{c}",
    fontSize: 14,
    rich: {
      name: {},
    },
  };
  
 
