import axiosRequest from "../../utils/axiosRequest"
import { FETCH_SEARCH_LOADING, FETCH_SEARCH_SUCCESS } from "./searchActionTypes"

export const fetYearsData = () => dispatch => {
    dispatch({
        type: FETCH_SEARCH_LOADING
    })
    axiosRequest.get(process.env.REACT_APP_YEAR_SEARCH)
        .then(res => {
            dispatch({
                type: FETCH_SEARCH_SUCCESS,
                payload: res.data
            })
        })
        .catch(err => {
            console.log(err)
        })
}