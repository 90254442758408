import React from 'react'
import image1 from '../../assets/images/1.png'
import image2 from '../../assets/images/2.png'
import image3 from '../../assets/images/3.png'
import image4 from '../../assets/images/4.png'
import image5 from '../../assets/images/5.png'

const Images = () => {
    return (
        <div className="images__container">
            <img src={image1} className="w-100" style={{height: 405}} alt=""/>
            <img src={image2} className="w-100" style={{height: 405}} alt=""/>
            <img src={image3} className="w-100" style={{height: 405}} alt=""/>
            <img src={image4} className="w-100" style={{height: 405}} alt=""/>
            <img src={image5} className="w-100" style={{height: 405}} alt=""/>
            
        </div>
    )
}

export default Images
