import axiosRequest from "../../utils/axiosRequest"
import { FETCH_TAB_LOADING, FETCH_TAB_SUCCESS } from "./tabActionTypes"

export const fetTabbarData = () => dispatch => {
    dispatch({
        type: FETCH_TAB_LOADING
    })
    axiosRequest.get(process.env.REACT_APP_AVAIABLE)
        .then(res => {
            dispatch({
                type: FETCH_TAB_SUCCESS,
                payload: res.data.availablecategory
            })
        })
        .catch(err => {
            console.log(err)
        })
}