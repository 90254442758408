import { Typography } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Zoom from "@material-ui/core/Zoom";
import * as echarts from "echarts";
import React, { useEffect, useRef } from "react";
import { FiZoomIn } from "react-icons/fi";
import styled from "styled-components";
import "../../utils/chartTheme";
import ChartCard from "../ChartCard";
const ZoomIcon = styled(FiZoomIn)`
  &:hover {
    transform: scale(1.1);
  }
`;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "10px",
    width: "65%",
    height: "75%",
    borderRadius: 8,
    marginTop: "5%",
    marginLeft: "5%"
  },
}));

const PreviewChartCard = ({ options, title, slug, theme, monthTitle }) => {
  console.log('previewchartData' , theme );
  const myChart = useRef(null);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [mainView, setMainView] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    setMainView(true);
  };

  const handleClose = () => {
    setOpen(false);
    setMainView(false);
  };

  /**
   * Initialize echarts
   */
  useEffect(() => {
    if(Object.keys(options).length == 0) return;
    if (options) {
      const charts = echarts.init(myChart.current, theme, {
        devicePixelRatio: 3,
      });
      //show lebel for pie chart
      if( options.tooltip.chart === 'pie') {
        options.legend.show = true;
      }else {
        options.legend.show = false;
      }
      options?.series.forEach(element => {
        if( 'label' in element && options.tooltip.chart != 'pie'){
          element.label.show = false;      
        }else {
          element.label.show = true;      

        }
      });
      charts.setOption(options);
    }
  }, [options]);

  let modifiedOptions = {
    ...options,
    toolbox: {
      ...options.toolbox,
      show: true,
    },
    tooltip: {
      ...options.tooltip,
      show: true,
    },
  };

  if (modifiedOptions?.xAxis?.type === "category") {
    modifiedOptions = {
      ...modifiedOptions,
      xAxis: {
        ...modifiedOptions.xAxis,
        axisLabel: {
          ...modifiedOptions.xAxis.axisLabel,
          show: true,
          rotate: 0,
        },
      },
    };
  }

  if (modifiedOptions?.yAxis?.type === "category") {
    modifiedOptions = {
      ...modifiedOptions,
      yAxis: {
        ...modifiedOptions.yAxis,
        axisLabel: {
          ...modifiedOptions.yAxis.axisLabel,
          show: true,
          rotate: 0,
        },
      },
    };
  }

  if (modifiedOptions?.tooltip?.chart === "pie") {
    modifiedOptions = {
      ...modifiedOptions,
      toolbox: {
        ...modifiedOptions.toolbox,
        feature: {
          ...modifiedOptions.toolbox?.feature,
          dataView: {
            ...modifiedOptions.toolbox?.feature?.dataView,
            show: true,
          },
        },
      },
    };
  }

  return (
    <>
      <div
        className="preview__chart"
        ref={myChart}
        style={{
          width: "100%",
          height: "100%",
          minHeight: 400,
          cursor: "pointer",
        }}
        // onClick={handleOpen}
      >
        {/* <ReactEcharts option={options} theme="shine" notMerge={true} lazyUpdate={true} style={{ height: 400 }}></ReactEcharts> */}
      </div>
      <ZoomIcon
        style={{
          fontSize: 25,
          color: "seagreen",
          position: "absolute",
          top: 20,
          right: 20,
          cursor: "pointer",
        }}
        onClick={handleOpen}
        title="Zoom In"
      />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
      >
        <Zoom in={open} style={{ transitionDelay: "100ms" }}>
          <div className={classes.paper}>
            <Typography variant="h6" align="center">
              {monthTitle != undefined ? monthTitle : title}
            </Typography>
            <ChartCard
              options={modifiedOptions}
              title={monthTitle != undefined ? monthTitle : title}
              slug={slug}
              handleClose={handleClose}
              theme={theme}
              mainView={mainView}
            />
          </div>
        </Zoom>
      </Modal>
    </>
  );
};

export default PreviewChartCard;
