const { FETCH_SEARCH_SUCCESS, FETCH_SEARCH_LOADING } = require("./searchActionTypes");

const initialState = {
    loading: false,
    data: []
}

const searchsReducer = (state = initialState, action) => {
    const { type, payload } = action;
   
    switch (type) {
        case FETCH_SEARCH_LOADING:
            return {
                ...state,
                loading: true
            }
        case FETCH_SEARCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload
            }
        default:
            return state;
    }

}

export default searchsReducer